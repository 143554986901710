.container {
    width: 100%;
    display: block;
    background-color: #FAF3E0;
    height: 130px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.mainHeader {
    margin: auto;
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 4;
}