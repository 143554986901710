.container {
    max-width: 80%;
    margin: 0 auto;
    padding: 20px;
    line-height: 1.6;
    font-family: Arial, sans-serif;
  }
  
  .heading {
    text-align: center;
   
    font-size:36px;
    color: #333;
    font-family: Poppins;
    font-weight: 500;
    margin-bottom: 20px;
  }
  
  .heading2{
    margin-top: 30px;
    font-size:24px;
    color: #333;
    font-family: Poppins;
    font-weight: 500;
  }
  
  .text{
    font-family: Poppins;
    font-size: 14px;
    color: #59595A;
    font-weight: 400;
    margin-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 15px;
    }
    h3 {
      font-size: 1.3em;
    }
    p {
      font-size: 0.9em;
    }
  }
  @media (max-width: 408px) {
    .container {
        width: 95%;
    }
  }