.contactMain {
    display: flex;
    justify-content: space-between;
    margin: 40px 10%;
    gap: 4%;
}

.loaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;    
}

.contactCol1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    /* border: 1px solid #E6E6E6; */
    box-shadow: 0 4px 8px #E6E6E6, 0 6px 20px #E6E6E6;
    padding: 40px;
    width: 30%;
    color: #333333;
    font-size: 16px;
    line-height: 30px;
}

.contactCol2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    box-shadow: 0 4px 8px #E6E6E6, 0 6px 20px #E6E6E6;
    padding: 40px;
    width: 60%;
}

.contactCol2 h2 {
    color: #1A1A1A;
    font-size: 24px;
    margin: 0px;
}

.contactCol2 p {
    color: #808080;
    font-size: 14px;
    margin: 0px;
}

.contactRow1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    text-align: center;
}

.hline {
    border: none;
    border-top: 1px solid #D1D1D8;
    width: 100%;
    margin: 20px auto;
    opacity: 0.7;

}


.profileform {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    margin: 4% 0px;
}

.profilerow {
    display: flex;
    gap: 15px;
}

.profileformGroup {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.profileformGroup label {
    font-size: 14px;
    color: #555;
    margin-bottom: 8px;
}

.profileformGroup input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
}

.profileformGroup textarea {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
}

.buttonGroup {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
}

.saveButton {
    background-color: #C86550;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.saveButton:hover {
    background-color: #C86550;
    color: white;
}

@media  (max-width: 1023px){
    .contactMain{
        flex-direction: column-reverse;
        gap: 30px;
        margin: 20px 20px;
    }
    .contactCol1{
        width: auto;
        padding: 20px;
    }
    .contactCol2{
        width: auto;
        padding: 20px;
    }
    .profilerow{
        flex-direction: column;
    }
    .profileformGroup input{
        font-size: 12px;
    }
}