
.section{
height: 90%;
width: 97%;

justify-content: center;
display: flex;
align-items: center;
justify-content: space-between;
}
.text{
    font-family: Rubik;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 3%;
    color: #C86550;
    

}
.dropdownContainer {
    position: relative;
    width: 40%;
 
  }
  
  .customSelect {
    position: relative;
    cursor: pointer;
    
    padding: 5px;
    border-radius: 4px;
  }
  
  .selectedOption {
    display: flex;
    align-items: center;
    font-family: Poppins;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 3%;
    color: #C86550;
  }
  
  .optionsList {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: white;
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 10;
  }
  
  .customSelect:hover .optionsList,
  .customSelect:focus .optionsList {
    display: block;
  }
  
  .option {
    padding: 5px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .option:hover {
    background: #f0f0f0;
  }
  
  .selected {
    font-weight: bold;
  }
  .line {
    width: 0.5px; /* Thickness of the line */
    height: 22px; /* Adjust height as needed */
    background-color: #dfdede; /* Color of the line */
    margin: 0 auto; /* Center the line horizontally within its container */
    display: block;
  }
  .right{
    display: flex;
    align-items: center;
    gap: 30px;
  }
  @media (max-width: 470px) {
    .text{
        font-family: Rubik;
        font-weight: 400;
        font-size: 11px;
        line-height: 11px;
        letter-spacing: 3%;
        color: #C86550;
        
    
    }
    .right{
        
        gap: 10px;
      }
      .dropdownContainer {
        position: relative;
        width: 60%;
       
      }
      
  }
  @media (max-width: 351px) {
    .text{
        font-family: Rubik;
        font-weight: 400;
        font-size: 9px;
        line-height: 11px;
        letter-spacing: 3%;
        color: #C86550;
        
    
    }
    .right{
        
        gap: 8px;
      }
      .dropdownContainer {
        position: relative;
        width: 60%;
       
      }
  }