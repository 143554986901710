.myprofile{
    display: flex;
    gap :40px;
  width: 75%;
  
}
.orderContainer {
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 8px;
    width: 100%;
}
.orderContent {
    display: flex;
    /* justify-content: center;
    align-items: center;
    padding:20px; */
}
.orderheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    border-bottom: 1px solid #eee;
}

.orderheader h2 {
    font-size: 20px;
    color: #1A1A1A;
  font-family: Poppins;
  font-weight:500 ;
  line-height: 30px;

    /* margin: 0px; */
}

.ordereditButton {

    color: #e74c3c;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    font-family: Poppins;
}

.orderhistory{
    width: 100%;
}

.orderhistory table {
    width: 100%;
    border-collapse: collapse;
    font-size: 1rem;
  }

  .orderhistory th, .orderhistory td {
    padding: 12px 15px;
    text-align: left;
    color: #4D4D4D;
   

  }
  
  .orderhistory thead {
    background-color: #faf1d7;
  }
  
  .orderhistory th {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #4D4D4D;
    font-family: Poppins;
  }
  .orderhistory td {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #4D4D4D;
    font-family: Poppins;
  }
  
  .orderhistory td a {
    color: #C86550;
    text-decoration: none;
    font-weight: bold;
  }
  
  .orderhistory td a:hover {
    text-decoration: underline;
  }
  

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px; /* Space between buttons and text */
    margin-top: 20px;
  }
  
  .paginationButton1 {
    
    border: none;
    color: white;
    padding: 10px 0px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    background-color: transparent;
    margin-top: 5px;
  }
  
  .paginationButton1:disabled {
    
    cursor: not-allowed;
  }
  
  
  .paginationleft{
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
        margin-right: 20px;
        margin-top: 5px;
  
  }
  .paginationButton {
    padding: 8px;
    border: none;
    background-color: white;
    color: #858585;
    cursor: pointer;
    margin: 0 0px;
    border-radius: 40px;
    font-size: 14px;
    width: 35px;
    height: 35px;
    border: 1px solid #d6cccc;
  }
  
  /* Active pagination button style */
  .paginationButton.active {
    background-color: #C86550; /* Change this color to your preferred active color */
    color: white;
  }

  .mobile_orderContent{
    display: none;
  }

/* Mobile order history */
.mobile_order_status.processing {
  color: #e74c3c;
  font-weight: bold;
}
.mobile_order_card{
  display: flex;
  justify-content: space-between;
  padding: 40px 20px 0px 20px;
}
.mobile_order_details h3{
  font-size: 16px;
  color: #1A1A1A;
  margin: 0px 0px 0px 0px;
}
.mobile_order_details p{
  font-size: 13px;
  color: #868889;
  font-weight: 600;
  margin: 6px 0px 8px 0px;
}
.mobile_order_info{
  font-size: 13px;
  color: #1A1A1A;
  font-weight: 600;
}
/* .mobile_order_icon{
  margin-top: 20px;
} */
.mobile_order_arrow{
  margin-top: 20px;
}
.mobile_order_card_row{
  display: flex;
  gap: 20px;
}

.myprofilemobile{
  display: none;
}

@media  (max-width: 768px){
  
  .orderContainer{
    border: none;
}
  .orderheader{
      padding: 20px 20px;
  }
    .promenu{
        display: none;
    }
    .myprofile{
        padding: 0px 0px;
    }
    .mobile_orderContent{
      display: block;
    }
    .orderContent{
      display: block;
    }
}

/* @media (min-width: 768px) and (max-width: 1023px) {
    .myprofile{
        padding: 0px 30px;
        gap: 20px;
    }
    .orderhistory th, .orderhistory td {
        padding: 12px 8px;
    }
} */
/* @media (min-width: 1024px) and (max-width: 1440px) {
    .myprofile{
        padding: 40px 30px;
        gap: 20px;
    }
} */


@media  (max-width: 768px){
  .myprofile{
    display: none;
  }
  .myprofilemobile{
    display: block;
    display:  flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  

  }
  .orderHistoryContainer {
    padding: 20px;
    background-color: #ffffff;
    width: 80%;
  
  }
  
  .title {
    font-size: 20px;
    font-family: Poppins;
    font-weight:600;
    color: #000000;
    opacity: 0.5;
    margin-bottom: 20px;
    line-height: 22.5px;
  }
  
  .orderItem {
    display: flex;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid #eeeeee;
  }
  
  .iconContainer {
    margin-right: 15px;
  }
  
  .iconContainer {
    width: 66px;
    height: 66px;
    border-radius: 50%;
    background-color: #F9F1E7;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .boximg{
    width: 42px;
    height: 29.24px;
  }
  
  .orderDetails {
    flex: 1;
  }
  
  .orderId {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }
  
  .orderDate {
    font-size: 10px;
    color: #888888;
    font-family: Poppins;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 3%;
  }
  
  .orderInfo {
   
    font-family: Poppins;
font-size: 10px;
font-weight: 600;
line-height: 15px;
letter-spacing: 0.03em;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

  }
  
  .total {
    font-weight: 700;
    color: #333333;
    font-size: 12px;
  }
  
  .status {
    font-size: 12px;
    color: #C86550;
  }
  
  .onTheWay {
    color: #ff8800;
  }
  
  .arrowIcon {
    color: #888888;
    font-size: 16px;
  }
  

}