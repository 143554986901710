.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 0 auto;
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
  
  .message {
    font-size: 16px;
    color: #777;
    margin: 10px 0;
  }
  
  .buttonGroup {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
  }
  
  .cancelButton {
    flex: 1;
    padding: 10px 20px;
    margin-right: 10px;
    background-color: transparent;
    border: 2px solid #cc614f;
    color: #cc614f;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .cancelButton:hover {
    background-color: #fce9e7;
  }
  
  .logoutButton {
    flex: 1;
    padding: 10px 20px;
    background-color: #eb300f;
    border: none;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .logoutButton:hover {
    background-color: #b84f41;
  }
  