.container {
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: auto;
  }
  
  .thankYou {
    font-size: 28px;
 line-height: 34px;
    font-weight: 500;
    font-family: Poppins;
  letter-spacing: -0.6px;
    color: #6C7275;
  }
  
  .orderReceived {
    font-family: Rubik;
    font-size: 32px;
    font-weight: 500;
    color: #333;
    line-height: 44px;
    letter-spacing: -0.4px;
  }
  
  .subtext {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    color: #333;
  margin-top: -10px;
    letter-spacing: -0.6px;
  }
  .productsimg{
    justify-content: center;
    display: flex;
    align-items: center;
    margin-top: 50px;
  }
  .products {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  
    width: 60%;
  }
  
  .product {
    position: relative;
    width: 75px;
    height: 84px;
  }
  
  .product img {
    width: 100%;
    height: 100%;
  
  }
  
  .productNumber {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #d86752;
    color: white;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
  
  .orderDetails {
  
    margin-bottom: 20px;
  
    justify-content: center;
text-align: left;
    display: grid;
  

  }
  
  .code {
    
   display: flex;
 
  }
  .code1 {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    color: #6C7275;
 width: 150px;
 
    
  }
  .code2 {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    color: #141718;
    text-align: right;

    
  }
  
  .continueShopping {
    background-color: #d86752;
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-size: 16px;
    font-family: Inter;
  }
  
  .continueShopping:hover {
    background-color: #c25644;
  }
  @media (max-width: 568px) {
    .container {
        text-align: center;
        padding: 10px;
        border-radius: 10px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        max-width: 280px;
        margin: auto;
      }
      .thankYou {
        font-size: 16px;
     line-height: 24px;
        font-weight: 500;
        font-family: Poppins;
      letter-spacing: -0.6px;
        color: #6C7275;
      }
      
      .orderReceived {
        font-family: Rubik;
        font-size: 20px;
        font-weight: 500;
        color: #333;
        line-height: 14px;
        letter-spacing: -0.4px;
      }
      
      .subtext {
        font-family: Poppins;
        font-size: 12px;
        font-weight: 500;
        color: #333;
        line-height: 34px;
        letter-spacing: -0.6px;
      }
      .products {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
      margin-top: -20px;
        width: 90%;
      }
      .code1 {
        font-family: Inter;
        font-size: 12px;
        font-weight: 600;
        color: #6C7275;
     width: 110px;
     
        
      }
      .code2 {
        font-family: Inter;
        font-size: 12px;
        font-weight: 600;
        color: #141718;
        text-align: right;
    
        
      }
  }
