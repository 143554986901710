.myprofile{
    width:70%;
    display: flex;
    gap :20px;

 background-color: #ffffff;
  

}
.prodetailsContainer {
 
    border: 1px solid #eee;
    border-radius: 8px;
    width: 100%;

}
.profilebodyContent {
    display: flex;
    justify-content: center;
    align-items: center;
 padding: 20px;
 


}
.profileheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    border-bottom: 1px solid #eee;
}

.pheading {
    font-size: 20px;
    color: #333;
    font-family: Poppins;
    font-weight: 500;
    /* margin: 0px; */
}
.editButton {
    background-color: #d87156;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;
}
.editButton:hover {
    background-color: #ffffff; /* Darken the button color on hover */
    color:  #d87156;
    border: #d87156 solid 1px;
}

.prodetails{
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 20px;

    border: 1px solid #eee;
    width: 60%;
    height: 271px;
   
}

.prorow{
    display: flex;
    gap: 60px;
   
  
}

.procustomer{
    width: 122px;
    font-family: Poppins;
    font-weight: 400;
    font-size: 16px;
  color: #808080;

}
.procname {
    width: 60%;
    font-family: Poppins;
    font-weight: 500;
    font-size: 16px;
    color: #1A1A1A;
    word-wrap: break-word; 
    overflow-wrap: break-word; 
}
@media  (max-width: 939px){
    .prodetails{
        width: 90%;
        
    }
}




@media  (max-width: 769px){
    .myprofile{
        width:100%;
    }
    .sidebar{
        display: none;
    }
    .prodetails{
        width: 100%;
     
        border: 0px solid #eee;
        padding:0px;
    }
    .procustomer{
        width: 122px;
    }
   
    .profilebodyContent {
        padding: 20px;
        margin-top: 20px;
       
     
    }
    .procname {
        width: 40%;
    }
    .prodetailsContainer {
        border: none;
        border-radius: 0px;
    }
    .procustomer{
        font-size: 13px;
    
    }
    .procname {
        font-size: 13px;
        font-weight: 500;
    }
    .prorow{
        gap: 10px;
    }
    .profileheader {
border-bottom:1px solid #e0d9d9 ;

    }
    .prodetailsContainer {
        box-shadow:  none
    }
   
}


@media  (max-width: 1140px){
    .prorow{
        display: flex;
        gap: 20px;
       
    }   
}

