.container {
    width: 100%;
  display: flex;

  border-radius: 12px;

  overflow: hidden;
  justify-content: center;
  align-items: center;



}
.section{
    width: 90%;
gap: 30px;
    display: flex;
    justify-content: center;
  
}

.sidebar {
    width: 256px;
  background-color: #F8F8F8;
height: 100%;
border-radius: 10px;
  display: flex;
  flex-direction: column;
 
}

.topic {
   

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 2%;
  margin-left: 30px;
padding-top: 19px;
padding-bottom: 19px;
margin-right: 30px;

  color:#000000;
  font-family: Poppins;
  cursor: pointer;
  transition: background 0.3s ease;
  /* border-bottom: 1px solid #E4E4E4; Adjust the thickness (length) of the border */
 
}
.topicBorder{
    border-bottom: 1px solid #d5cfcf;
}
.topic.active {
  background-color: #C86550;
  color: white;
  border-radius: 10px;
  border: none;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 25px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 2%;
  padding-top: 10px;
padding-bottom: 10px;

}

.content {
  width: 55%;
padding: 10px;
padding-left: 30px;
  background-color: #F7F7FB;
  border-radius: 20px;

}
.heading{
    font-family: Poppins;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 2%;
}

.faqItem {
  margin-bottom: 20px;
  
}

.question {
    font-family: DM Sans;
  padding: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  background-color: #FFFFFF;


  border-radius: 8px;
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.3); /* Bottom shadow effect */
}

.answer {
    font-family: DM Sans;
    padding: 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #A69E9E;
  padding: 10px;
  margin-top: -10px;
  background-color: #FFFFFF;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.3); /* Bottom shadow effect */
 
}
.line10{
    width: 10px;
    height: 2px;
    background-color: palegreen;
}
.nagative{
 width: 20px;
 height: 20px;
 background-color: #C86550;   
 align-items: center;
 display: flex;
 justify-content: center;
 color: #FFFFFF;
 border-radius: 3px;
}
.positive{
    width: 20px;
 height: 20px;
    background-color: #FFFFFF;
    align-items: center;
    font-size: 18px;
 display: flex;
 justify-content: center;
 color:#C86550 ;
 border: 1px solid #C86550;
 border-radius: 3px;
}
.mobile_contant{
  display: none;
}
@media  (max-width: 769px){
  .content{
    display: none;
  }
  .mobile_contant{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
    .sidebar {
        display: none;
        
    }
    .container {
        margin-top: 1rem;
    }
    .content {
        width: 100%;
        padding: 20px;
    }
    .content1{
      width: 95%;
    }
    .section{
        width: 100%;
    }
}
@media  (max-width: 352px){
    .question {
        font-size: 12px;
    }
    .answer {
        font-size: 12px;
    }
}