.profilebodyContainer {
    background-color: #fff;
   /*  padding: 24px; */
    border: 1px solid #eee;
    border-radius: 8px;
    width: 70%;
    
    /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); */
}
.errorMessage{
    color: #ce5a3d;
    margin-top: -10px;
    font-size: 13px;
}

.profileheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    border-bottom: 1px solid #eee;
}

.pheading{
    font-size: 20px;
    color: #333;
    font-family: Poppins;
    font-weight: 500;
    
}

.editButton {
    background-color: #d87156;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease; /* Smooth transition for hover */
}

.editButton:hover {
    background-color: #ffffff; /* Darken the button color on hover */
    color:  #d87156;
    border: #d87156 solid 1px;
}

.profilebodyContent {
    display: flex;
    justify-content: center;
    align-items: center;
    padding:20px;
}

.profilebodyDetails {
    display: flex;
    flex-direction: column;
    margin-right: 32px;
    width: 70%;
}

.profilebodyDetails label {
    display: block;
    font-size: 14px;
    color: #1A1A1A;
    margin-bottom: 8px;
    font-family: Poppins;
    font-weight: 400;
}

.profilebodyDetails input {
   /*  width: 70%; */
    padding: 10px;
    font-size: 16px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #666666;
    font-family: Poppins;
    
}

.profilebodyDetails input:disabled {
    background-color: #f3f3f3;
}

.savechanges {
    background-color: #d87156;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 16px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 16px;
    width: 100%;
}
.savechanges:hover {
    background-color: #ce5a3d ; /* Darken the button color on hover */
    color: floralwhite;
   
}

.profilebodyImage {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
}

.profilebodyImage .avatar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 16px;
}

.changeImageButton {
    background-color: transparent;
    border: 1px solid #d87156;
    color: #d87156;
    border-radius: 5px;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;
}

@media  (max-width: 768px){
    .profilebodyContainer {
        border: none;
        width: 90%;
    }
    .profilebodyDetails {
        width: 100%;
        margin-right: 0px;
    }
    .savechanges {
        width: 100%;
    }
    .profileheader h2 {
        font-size: 20px;
    }
}