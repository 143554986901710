/* Background with blur */
.blur_background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(8px);
  /* Blur the background */
  background: rgba(0, 0, 0, 0.4);
  /* Optional: dark overlay for contrast */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.invoiceBtn:hover {
  background-color: #0056b3; /* Darker shade for hover effect */
}

.invoiceBtn:disabled {
  background-color: #cccccc; /* Gray color for disabled state */
  color: #666666;
  cursor: not-allowed; /* Show not-allowed cursor */
}

.popup_head {
  display: flex;
  align-items: center;
}

.popup_head span {
  font-size: 22px;
  font-weight: 700;
  color: #131118;
}

/* Popup card styling */
.popup_card {
  position: relative;
  background-color: white;
  padding: 50px;
/*   border-radius: 12px; */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  /* width: 300px; */
  /* Default width for the card */
}

.orderbuttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.trackOrderBtn,
.invoiceBtn {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.trackOrderBtn {
  background-color: #fff;
  color: #C86550;
  border: 1px solid #C86550;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.trackOrderBtn:hover {
  background-color: #C86550;
  color: #fff;
  transform: scale(1.1);
}

.invoiceBtn {
  background-color: #C86550;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.invoiceBtn:hover {
  background-color: #fff;
  color: #C86550;
  border: 1px solid #C86550;
  transform: scale(1.1);
}

/* Close button */
.close_btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #888;
}

.close_btn:hover {
  color: black;
}

/* Icon margin */
.reviewicon {
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.reviewSection {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 20px;
}

.reviewprod {
  display: flex;
  gap: 10px;
  align-items: center;
  border-bottom: 1px solid #E6E6E6;
  padding: 10px 0px;
}

.reviewtextarea {
  width: 94%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
}
.reviewsub{
  font-size: 11px;
  color: #7E7E7E;
}






/* Media Queries */

/* For mobile screens */
@media (max-width: 768px) {
  .popup_card {
    width: 90%;
    margin: 20px;
    padding: 15px;
  }
  .popup_head{
    font-size: 16px;
  }
  .popup_head span{
    font-size: 16px;
  }
}

/* For tablet screens */
@media (min-width: 768px) and (max-width: 1023px) {
  .popup_card {
    width: 400px;
    /* Slightly larger popup on tablets */
  }
}

/* For small desktop screens */
@media (min-width: 1024px) and (max-width: 1279px) {
  .popup_card {}
}