.slidercontant {
  width: 80%;
  margin: 0 auto;
  z-index: 10;
}

.containes {
  padding-top: 20px;
}

.breadcrumbs {
  padding-inline: 6%;
  padding-block: 2%;
  text-decoration: none;
  cursor: pointer;
}


.card1{
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;

}
.card2{
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 10 !important;
}
.navbutton1{
position: absolute;
display: flex;
width: 90%;
justify-content: space-between;
}

.prev {
  z-index: 10 !important
}

.next {
  z-index: 10 !important
}



.container{
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    justify-content: space-between;
 
    
}
.dropdownContent img {
  max-width: 100%;
  display: inline-block; /* Allow images to align side by side */

}

.dropdownContent p {
      color: #555;
}



.section1{
    width: 60%;

    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   position: sticky;
   top:0;
    margin-top: 2rem;
    
}
.section2{
    width: 40%;

    height: 80%;
  
}
.box1{
    width: 30%;
    height: 100%;

    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    padding: 5px;
}
.box2{
    width: 65%;
    height:  100%;

  height: 506px;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;

    padding: 5px;
}

.mainImg{
   width: 100%;
   height: 620px;
   object-fit: cover;
  /* width: 580px;
  height: 620px; */
}
/* .mainImage{
    width: 404px;
    height: 356px;
} */
.heading {
    font-size: 28px;
    margin-bottom: 10px;
    font-family: Rubik;
    font-weight: 400;
    line-height: 43.52px;
    letter-spacing: 2%;
    width: 353px;

  }
  
  .ratingPrice {
    display: grid;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 10px;
  }
  
 
  .star{
    color: #fbc02d;
    font-size: 44px;
    margin-top: 20px;
  }
  .num{
    font-size: 18px;
    font-family: Rubik; 
  font-weight: 500;
  margin-left: 10px;
  margin-top: -20px;
  color: #807D7E;



  }
  
  .price {
    font-size: 23px;
    font-family: Jost;
    
  }
  
  .originalPrice {
    text-decoration: line-through;
    margin-right: 10px;
    color: #999;
    font-weight: 400;
  }
  
  .discountedPrice {
 font-weight: 500;
  }
  
  .ptext{
    font-family: Rubik;
    font-weight: 500;
    font-size: 18px;
    color: #3F4646;
    line-height: 21.33px;
    margin-top: 30px;
    
  }
  
  .sizeOptions {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  .sizeButton {
    background-color: #ffffff;
    color: #000000;
    padding: 10px 15px;
    border: 2px solid #BEBCBD;
    cursor: pointer;
    font-family: Rubik;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.41px;
    color: #3C4242;
  }
  .cartButton{
   
      background-color: #ffffff;
      color: #000000;
      padding: 10px 15px;
      border: 1px solid #BEBCBD;
      font-family: Rubik;
      font-size: 13px;
      font-weight: 400;
      line-height: 15.41px;
   
  }


  .cartInputContainer {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    max-width: 100px;
  
  }
  
  .cartInput {
    width: 30px;
    text-align: center;
    border: none;
    outline: none;
    font-size: 1rem;
    background-color: transparent;
  
  }
  
  .counterButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: none;
    /* background-color: #e0e0e0;  */
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    transition: background-color 0.2s ease;
  }
  
  .counterButton:hover {
    background-color: #d0d0d0;
  }
  
  .counterButton:active {
    background-color: #c0c0c0;
  }
  
  .cartInputContainer input:focus {
    border: none;
    outline: none;
  }
  
  .g_text {
    /* margin-bottom: 8px; */
    font-weight: bold;
  }
  
/* 
  .cartInput {
    width: 40px;
    height: 40px;
    text-align: center;
    padding: 0;
    margin-right: 10px; 
    border: 1px solid #ccc;
    border-radius: 5px; 
    font-size: 16px;
  } */
  
  

  .sizeButton {
    padding: 8px 12px;
    margin: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
    position: relative;
  }
  
  .colorCircle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: none; /* Default no border */
    transition: border 0.2s ease;
  }
  
  .activeColor {
    border: 2px solid black; /* Add black border for selected color */
    padding: 3px; /* Adds spacing inside the border */
  } 
  
  .activeSize {
    border: 2px solid red;
  }

  .innerCircle {
    width: 90%; /* Creates the smaller filled circle */
    height: 90%;
    border-radius: 50%;
  }
  
  .unavailable {
    cursor: not-allowed;
    opacity: 0.3;
  }

  .unavailableSize {
    cursor: not-allowed;
    opacity: 0.3;
    overflow: hidden;
  }
  
  .crossLineColor::after {
    content: "";
    position: absolute;
    top: 13px;
    left: -8px;
    width: 150%;
    height: 2px;
    background-color: rgb(24 30 25);
    transform: rotate(-55deg);
    transform-origin: center;
  }


  .crossLineSize::after {
    content: "";
    position: absolute;
    top: 14px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: red;
    transform: rotate(-30deg);
    transform-origin: center;
  }
  
  
  .colorsAvailable {
    margin-bottom: 30px;
    margin-top: 30px;
    display: flex;
  }
  
  .colorOptions {
    display: flex;
    gap: 10px;
  }
  
  .colorCircle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #fff;
  }
  
  .black { background-color: #000; }
  .yellow { background-color: #fbc02d; }
  .pink { background-color: #f48fb1; }
  .red { background-color: #f44336; }
  
  .cartbutton{
display: flex;

align-items: center;
height: 40px;

gap: 10px;
font-family: Rubik;
font-weight: 500;
font-size: 18px;
color: #000000;

  }
  .addToCart {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D85D5D;
    padding: 15px 35px;
    border: none;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    font-family: Rubik;
    font-weight: 600;
    font-size: 15px;
    
  
  }
  
  .cartIcon {
    margin-right: 10px;
   
  }
  
  .additionalInfo {
    display: flex;
    justify-content: space-between;
    
    flex-wrap: wrap;
 
  align-items: center;
   justify-content: left;
    width: 100%;
    margin-top: 40px;
margin-bottom: 3rem;
  }
  
  .infoItem {
    display: flex;
    align-items: center;
width: 280px;

    justify-content: left;
  
  }
  .Ftext{
    font-family: Rubik;
    font-weight: 500;
    font-size: 15px;
    color: #3F4646;
    line-height: 21.33px;
    margin-left: 10px;
  }
 .icon {
    margin-left: 8px;
    font-size: 12px;
  }
 .productDescriptionButton {
   background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
padding: 0px;
    width: 100%;
    margin-bottom: 30px;
    
  }
  
  .productDescriptionTitle {
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 8px;
    
    justify-content: space-between;
 

padding-right: 10px;
    width: 95%;
    height: 40px;

    border: 1px solid rgb(179, 168, 168);
  }
  .ucontant{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    
  }
  .text_f{
font-family: Poppins;
font-size: 18px;
font-weight: 500;
line-height: 33.5px;
color: #000;
  }
  .icon {
    margin-left: 8px;
    font-size: 12px;
  }
  
  
  
  .productDescription {
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 2%;
    color: #555;
    margin-bottom: 3rem;
    margin-top: 3rem;
    line-height: 1.5;

   justify-content: center;
  }
  
  .descriptionGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three columns layout */
    gap: 0px;
    background-color: rgb(248, 245, 251);
    padding: 10px;
    width: 90%;
  

  }
  
  .descriptionItem1 {
  
   justify-content: center;
    border-right: 2px solid #eee;
   display: flex;
   flex-direction: column;
 
   padding: 10px;
  }
  .descriptionItem2 {
    border-right: 2px solid #eee;
    
    padding: 10px;
    
  }
  .descriptionItem3 {
  padding: 10px;
   
  }
  .descriptionItem4 {
   
    border-right: 2px solid #eee;
    border-top: 2px solid #eee;
    
    padding: 10px;
   
  }
  .descriptionItem5 {
    
    border-right: 2px solid #eee;
    border-top: 2px solid #eee;
    padding: 10px;
   
  }
  .descriptionItem6 {
    
   border-top: 2px solid  #eee;
    
    padding: 10px;
   
  }

  
  .label {
    font-family: Rubik;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 2%;
    color: #888;
    margin-bottom: 5px;
    
  }
  
  .value {
    font-family: Rubik;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 2%;
   
    font-weight: bold;
    color: #3C4242;
  }
  .newBornSection {
    margin-top: 40px;
  width: 95%;
  padding: 10px;
  }
  
  .mainPhoto {
    width: 100%;
   height: 328px;
  }
  
  .productTitle {
    font-size: 18px;
    font-weight: 500;
    margin: 20px 0;
    font-family: Rubik;
    line-height: 21.33px;
  }
  
  .productFeatures {
    list-style-type: disc;
    margin-left: 0px;
  }
  
  .productFeatures li {
    margin-bottom: 5px;
    font-size: 16px;
    color: #555;
    font-family: Rubik;
    font-weight: 400;
    line-height: 29px;
  }
  
  /* Baby Vests Section */
  .babyVests {
    margin-top: 3rem;
 margin-bottom: 3rem;
  }
  .babyNext{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
  .babyTitle{
    margin-bottom: 20px;
    font-size: 12px;
    color: #010101;
    font-family: Rubik;
    font-weight: 500;
    line-height: 0px;
  }
  
  .vestCard {
    width: 30%;
    text-align: center;
  
    text-align: left;
  }
  
  .vestImage {
    width: 100%;
   
  }
  
  .vestTitle {
    font-size: 16px;
    font-weight: 400;
    font-family: Rubik;
    color: #555;
    line-height: 20px;
  }
  
  .vestDescription {
    font-family: Rubik;
    font-size: 12px;
    color: #555;
  }  
  @media (max-width: 1400px) {
    .infoItem {  
  width: 230px;

   }
  }
  @media (max-width: 1204px) {
    /* .mainImage{
      width: 474px;
      height: 576px;
  }
  .infoItem {  
    width: 280px;

     } */
  }
  @media (max-width: 1024px) {
    .mainImg{
   
      width:100%;
      height: 550px;
    }
  .mainImage{
    width: 324px;
    /* height: 476px; */
}
.box1{
  width: 20%;
  gap: 20px;
}
  }
  @media (max-width: 1025px) {
    .heading {
      width: 95%;
      font-size: 28px;
    }
    /* .mainImg{
   
      width: 580px;
      height: 620px;
    } */
  .section1{
    position: relative;
    flex-direction: column-reverse;
    width: 100%;
  }
  .container{
    flex-direction: column;
    align-items: center;
  }
  .section2{
    width: 95%;
  }
  .box1{
    flex-direction: row;
    width: 100%;
    margin-top: 50px;
  }
 
  /* .mainImage{
    width: 574px;
    height: 676px;
} */
.box2{
  width: 100%;
  height:  100%;
}
  }
  @media (max-width: 735px) {
    .ptext{
      font-weight: 400;
      font-size: 16px;
    }
    
    .heading {
      margin-top: 40px;
font-size: 16px;
    }
    .section1{
      width: 100%;
      margin-top: 0rem;
      position: relative;
    }
    .navbutton1{
      position: absolute;
        display: none;
       
      
      
      width: 90%;
      justify-content: space-between;
      }
    .box2{
      width: 100%;
      height:  100%;
    }
    .box1{
      gap: 20px;
      
    }
    .babyVests{
      justify-content: center;
      display: flex;
      flex-direction: column;
      width: 80%;
    }
    .babyTitle{
      text-align: left;
      width: 90%;
    
    }
     .babyNext{
    display: grid;
    justify-content: left;

 
  }

  
  .vestCard{
  
    width: 100%;
  }
    /* .mainImg{
   
      width: 80px;
      height: 67px;
  } */
  /* .mainImage{
    width: 474px;
    height: 576px;
} */
   
  }
 
  @media (max-width:600px) {
    .mainImg{
   
      width: 100%;
      height: 326px;
  }
  /* .mainImage{
    width: 374px;
    height: 476px;
} */
.box2{
  width: 100%;


}
.box1{
  gap: 10px;
  margin-top: 3rem;
  
}
  }
  @media (max-width:422px) {
    /* .mainImg{
   
      width: 40px;
      height: 40px;
  } */
  .mainImage{
    width: 274px;
    height: 276px;
}
/* .box2{
  width: 100%;
  height:  277px;

} */
.box1{
  gap: 25px;
  margin-top: 2rem;

}
.value {
  font-family: Rubik;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 2%;
 
  font-weight: bold;
  color: #3C4242;
}
.label {
  font-family: Rubik;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 2%;
  color: #888;
  margin-bottom: 5px;
  
}

  }
  @media (max-width:422px) {
    .addToCart {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #D85D5D;
      padding: 10px 28px;
      border: none;
      color: #fff;
      cursor: pointer;
      border-radius: 5px;
      font-family: Rubik;
      font-weight: 500;
      font-size: 12px;
      
    
    }
  }

  /* similer product sliders */

  .similerproduct {
    width: 90%;
    margin: auto;
 margin-top: 2rem;

    
  
   
  }
  .navbutton{
    display: flex;
  }
  
  .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  a {
    text-decoration: none !important;
  }
  .slider1 {
   
    /* text-align: center; */
    /* background: #719b98; */
    width: 100%;
    height: 100%; /* Adjust the height as needed */
   
   
  }
  .sec{
    justify-content: center;
    display: flex;


    
   
  }
  .sec01{
    height: 427.49px;
    width: 293px;

  justify-content: center;
  display: flex;
  border: 1px solid rgb(211, 211, 211);
  border-radius: 5px;
  text-decoration: none;
 

  }
  .maincontainer{
    width: 260px;
    height: 273px;
    margin-top: 10px;
    

  }
  .SlideImage{
    width: 100%;
    height: 100%;
   
  }
  .sale{
    position: absolute;
    color:rgb(247, 237, 237);
  
    height: 25px;
    width: 60px;
    text-align: center;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 3%;
 
  
  }
 .content{
  display: flex;
margin-top: 20px;
 justify-content: space-between;
align-items: center;

  width: 100%;

}
.right{
align-items: flex-start;
justify-content: center;
/* display: flex; */
flex-direction: column;
gap: 4px;
 
}
.stars{

 
  display: flex;


}
.price{
  display: flex;
  gap: 0px;
  margin-top: -10px;


}
.beforeprice{
  text-decoration: line-through;
}
.name {

  font-family: Rubik;
  letter-spacing: 0.005em;
  line-height: 17.2px ;
  font-weight: 400;
  font-size: 14px;
  margin-top: 10px;
  text-decoration: none;
  list-style: none;
  color: #232323;
}

.discoutprice{
  font-family: Rubik;
  font-size: 14px;
font-weight: 500;
line-height: 3px;
text-decoration: none;
color: #232323;

}
.beforeprice{
  font-family: Rubik;
  font-size: 12px;
  margin-left: 5px;
font-weight: 500;
line-height: 8px;
color: #A69E9E;
text-decoration: line-through;
}
.Like{

 margin-top: -15px;
 
}
.AddCart{
  margin-top: 15px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.59px;
  color: #374151;
  text-decoration: none;
  text-align: right;
  width:105px
}

.disabledButton {
  cursor: not-allowed;
  opacity: 0.5;
}

.similer_nav{
justify-content: center;
display: flex;
width: 100%;

}
.nav_sim{
  display: flex;
justify-content: space-between;
width: 95%;
align-items: center;
}
.sim_heading{
  font-family: Poppins;
  font-weight: 400;
  font-size: 24px;
  line-height: 33.5px;
  letter-spacing: 2%;
  color: #3C4242;
}
.navbutton{
  gap: 10px;
}
  @media (max-width:1310px) {
    .sec01{
    height: 370.49px;
    width: 245px;
  
  justify-content: center;
  display: flex;
  border: 1px solid rgb(211, 211, 211);
  border-radius: 5px;

  }
  .maincontainer{
    width: 210px;
    height: 213px;
    margin-top: 10px;
  }
  /* .content{
    width: 210px;
  } */
  }
   @media (max-width:1038px) {
  .sec01{
    height: 427.49px;
    width: 283px;
  
  justify-content: center;
  display: flex;
  border: 1px solid rgb(211, 211, 211);
  border-radius: 5px;

  }
  .maincontainer{
    width: 260px;
    height: 273px;
    margin-top: 10px;
  }
  .content{
    width: 265px;
  }
   }
     @media (max-width:980px) {
 .sec01{
    height: 427.49px;
    width: 223px;
  
  justify-content: center;
  display: flex;
  border: 1px solid rgb(211, 211, 211);
  border-radius: 5px;

  }
  .maincontainer{
    width: 210px;
    height: 213px;
    margin-top: 10px;
  }
  .content{
    width: 210px;
  }
     }
  @media (max-width:780px) {
    .sec01{
    height: 427.49px;
    width: 283px;
  
  justify-content: center;
  display: flex;
  border: 1px solid rgb(211, 211, 211);
  border-radius: 5px;

  }

  .breadcrumbs{
    
    display: none;
  }
  .maincontainer{
    width: 260px;
    height: 273px;
    margin-top: 10px;
  }
  .content{
    width: 265px;
  }
  }
  @media (max-width:650px) {
 .sec01{
    height: 427.49px;
    width: 223px;
  
  justify-content: center;
  display: flex;
  border: 1px solid rgb(211, 211, 211);
  border-radius: 5px;

  }
  .maincontainer{
    width: 210px;
    height: 213px;
    margin-top: 10px;
  }
  .content{
    width: 210px;
  }
  }
    @media (max-width:520px) {
       .sec01{
    height: 427.49px;
    width: 283px;
  
  justify-content: center;
  display: flex;
  border: 1px solid rgb(211, 211, 211);
  border-radius: 5px;

  }
  .maincontainer{
    width: 260px;
    height: 273px;
    margin-top: 10px;
  }
  .content{
    width: 265px;
  }
  .sim_heading{
  font-family: Poppins;
  font-weight: 400;
  font-size: 18px;
  line-height: 33.5px;
  letter-spacing: 2%;
  color: #3C4242;
}
.nav_sim{
  display: flex;
justify-content: space-between;
width: 98%;
align-items: center;
}
  
    }





    /* Review--- */


    .container44{
      padding: 20px;
      max-width: 100%;
      margin: auto;
      background-color: #fff;
      border-radius: 12px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      font-family: Arial, sans-serif;
    }
    
    .ratingSummary {
      display: flex;
      padding: 16px;
    border-radius: 8px;
      justify-content: space-between;
      background-color: #F8F8F8;
    }
    
    .averageRating {
      text-align: center;
    }
    
    .ratenum {
      font-size: 48px;
      margin: 0;
      color: #333;
      
    }
    
    .stars {
      color: #ffb400;
      font-size: 18px;
      margin-top: 4px;
    }
    
    .averageRating p {
      font-size: 14px;
      color: #757575;
      margin: 4px 0 0;
    }
    
    .ratingBreakdown {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    
    .ratingRow {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    
    .star_1{
      font-size: 14px;
      color: #333;
    }
    
    .ratingBar {
      width: 100px;
      height: 6px;
      background-color: #eee;
      border-radius: 3px;
      overflow: hidden;
    }
    
    .filledBar {
      height: 100%;
      background-color: #e57373;
    }

    .outOfStock{
      font-family: Jost;
font-size: 24px;
font-weight: 500;
line-height: 48px;
text-align: left;
color: #f30b0b;

    }

    .lowStock{
     color: red;
    }
    
    .reviews {
      margin-top: 16px;
      max-height: 300px; /* Adjust the height as needed */
      overflow-y: auto; /* Enables scrolling when content exceeds the height */
      padding-right: 4px; /* Adds padding for scroll bar space */
    }
    
    /* Add a custom scrollbar style if desired */
    .reviews::-webkit-scrollbar {
      width: 3px;
    }
    
    .reviews::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 4px;
    }
    
    .review {
      padding: 16px 0;
      border-bottom: 1px solid #eee;
    }
    
    .reviewHeader {
      display: flex;
      align-items: center;
      gap: 10px;
      position: relative;
    }
    
    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    
    .reviewInfo {
      flex-grow: 1;
    }
    
    .reviewInfo h3 {
      margin: 0;
      font-size: 16px;
      color: #333;
    }
    
    .reviewInfo p {
      margin: 0;
      font-size: 14px;
      color: #757575;
    }
    
    .time {
      margin-left: 8px;
    }
    
    .moreOptions {
      font-size: 28px;
      color: #000000;
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      width: 4px;
height: 16px;

opacity: 0px;

    }
    
    .reviewText {
      margin-top: 8px;
      font-size: 14px;
      color: #333;
    }
    

    @media (max-width:1250px) {
      .navbutton1{
        width: 95%;
      }
    }



    .container45 {
      background-color: #00000000; /* Black background */
      color: #fff; /* White text color */
      font-family: Arial, sans-serif;
      padding: 15px;
      border-radius: 8px;
  width: 90%;
      border: 1px solid silver;
      margin-bottom: 2rem;
     
    }
    .contant{
      display: flex;
      justify-content:flex-start;
     gap: 10px;
     width: 100%;
   
    }
    .contant46{
      width: 90%;
    }
    
    .pinCodeHeader {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      font-weight: bold;
      color: #7e899c; /* Slightly lighter gray */
      margin-bottom: 10px;
      
    }
    
    .deliveryIcon {
      font-size: 16px; /* Adjust icon size */
    }
    
    .pinCodeInput {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid silver;
      border-radius: 4px;
      padding: 5px;
    
      margin-top: -5px;
      width: 90%;
    }
    
    .pinCodeInput input {
      border: none;
      outline: none;
      font-size: 16px;
      color: #000;
      width: 100%;
      background-color: transparent;
     
    }
    
    .changeBtn {
      background-color: transparent;
      border: none;
      color: #C86550; /* Red color for the button */
      font-weight: bold;
      cursor: pointer;
      font-family: Poppins;
font-size: 12px;
font-weight: 600;
line-height: 33.5px;
letter-spacing: 0.02em;


    }
    
    
    .enter{
      font-family: Inter;
font-size: 15px;
font-weight: 700;
line-height: 20.57px;
color: #1D364D;

    }
    .availabilityStatus{
      font-family: Inter;
font-size: 14px;
font-weight: 500;
line-height: 16.94px;
color: #3C4242;

    }
    .deliveryError{
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.94px;
     color: black;
      
    }
    @media (max-width:450px) {

    }


    