.container{
    width: 100%;
 background-color:rgb(248, 248, 248);
 
    justify-content: center;
    display: flex;
    align-items: center;
    
}
.section{
    width: 90%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
    margin-bottom: 4rem;
   
  
}
.left{
    width: 40%;
    height: 80%;
   
  
}
.right{
    width: 40%;
    height: 80%;
   
 
}
.maincontainer{
    width: 417.84px;
    height: 456px;
 
}
.mainImg_1{
    width: 417.84px;
    height: 456px;
 
}
.heading{
    font-family: Rubik;
    font-size: 28px;
    font-weight: 500;
    line-height: 36.4px;
      }
      .head{
        font-family: Rubik;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
          }
          .button_2{
            padding: 10px 32px;
            color: #FFFFFF;
            background-color:#D0634F;
            font-family: Rubik;
        font-size: 14px;
        font-weight: 600;
        /* line-height: 24px; */
        cursor: pointer;
            width: fit-content;
            transition: 0.3s ease-in;
            margin-left: 0px;
            border-radius: 24px;
            border: 1px dashed #FFFFFF ;
            margin-top: 1rem;
        }
        .button_2:hover{
            background-color: rgba(255, 255, 255, 0.9);
            color: #D0634F;
            border: 1px dashed #D0634F;
        }   
        @media (max-width: 1200px) {
            .section{
                width: 100%;
                height: 80%;
              
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 4rem;
                margin-bottom: 4rem;
              
              
            }
        }  
        @media (max-width: 1080px) {
            .section{
                width: 100%;
                height: 80%;
              gap: 90px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 4rem;
                margin-bottom: 4rem;
              
              
            }
            .left{
            width: 45%;
            height: 80%;
         
        }
        .right{
            width: 40%;
            height: 80%;
        
        }
    }
    @media (max-width: 942px) {
        .section{
            width: 100%;
            height: 80%;
          gap: 20px;
            display: flex;
            justify-content: center;
            flex-direction: column-reverse;
            align-items: center;
         
          }
          .right{
            width: 80%;
            height: 80%;
        
          align-items: left;
          justify-content: left;
          display: flex;
          flex-direction: column;
        }
        .left{
            width: 80%;
            height: 80%;
          
           justify-content: center;
           align-items: center;
           display: flex;
        }
        .heading{
            font-family: Rubik;
            font-size: 28px;
            font-weight: 500;
            line-height: 36.4px;
            text-align: left;
              }
              .head{
                font-family: Rubik;
                font-size: 16px;
                font-weight: 400;
                line-height: 28px;
                text-align: left;
                  }
    }
    @media (max-width: 442px) {
        .section{
            margin-top: 1rem;
            margin-bottom: 2rem; 
        }
        .mainImg_1{
            width: 317.84px;
            height: 356px;
          
        }
        .maincontainer{
            width: 317.84px;
            height: 356px;
           
        }
        .heading{
            font-family: Rubik;
            font-size: 22px;
            font-weight: 500;
            line-height: 16.4px;
              }
              .head{
                font-family: Rubik;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                  }
    }
    @media (max-width: 342px) {
        .mainImg_1{
            width: 257.84px;
            height: 286px;
         
        }
        .maincontainer{
            width: 257.84px;
            height: 286px;
            
        }
        .section{
            width: 90%;
            height: 80%;
          
            display: flex;
            justify-content: center;
            align-items: center;
            /* margin-top: 2rem;
            margin-bottom: 2rem; */
           
          
        }
    }