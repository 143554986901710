.myprofile {
    display: flex;
    gap: 40px;
   width: 70%;

   
}

.ordetailsContainer {
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 8px;
    width: 100%;

}

.ordetailsheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    border-bottom: 1px solid #eee;
 
}

.ordetailsheader h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    color: #1A1A1A;
    /* margin: 0px; */
    font-family: Poppins;

}

.ordetails_status {
    display: flex;
    gap: 10px;
    align-items: center;
}

.ordetails_status .status {
    font-size: 14px;
    line-height: 21px;
    color: #4D4D4D;
    font-family: Poppins;
    font-weight: 400;
}

.status .changestatus {
    color: #ED0707 !important;
}

.ordetails_status .status span {
    font-size: 18px;
    color: #e75050;
}

.pnamereview {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.editButton {
    background-color: #d87156;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;
}

.reviewbtn {
    background-color: transparent;
    color: #d87156;
    border: none;
    padding: 0px 0px;
    cursor: pointer;
    font-size: 14px;
    text-decoration-line: underline;
}

.ordetailsbody {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 12px 16px;
    gap: 20px;
  
   
}

.ordetailsaddress {
    display: flex;
    align-items: flex-start;
    padding: 12px 0px;
    gap: 20px;
   
    /* font-family: Poppins;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 3%; */
}

.address_container {
    display: flex;
    /*   border-radius: 4px;
    border: 1px solid #E6E6E6; */
}

.address_box {
    border: 1px solid #E6E6E6;
}

.address_box .h3 {
    border-bottom: 1px solid #E6E6E6;
    padding: 16px;
    font-size: 14px;
 line-height: 14px;
    font-family: Poppins;
font-weight: 500;
 color: #999999;
 
 
}

.address_deets {
    padding: 12px 16px;

}

.namesr {
    display: flex;
    justify-content: space-between;
    padding: 0px 13px;
    color: #666666;
   
 
font-family: Poppins;
font-size: 14px;
font-weight: 400;
line-height: 21px;
text-align: left;

}
.number2{
  
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    margin-top: -2.5rem;
    text-align: left;
    
}
.number1{

font-family: Poppins;
font-size: 14px;
font-weight: 600;

text-align: left;
color: #1A1A1A;
}
.total_price{
   
font-family: Poppins;
font-size: 18px;
font-weight: 600;

text-align: right;
color: #d87156;

}
.name1{
  color: #1A1A1A;
font-family: Poppins;
font-size: 16px;
font-weight: 400;

text-align: left;

}
.discription{
 
font-family: Poppins;
font-size: 14px;
font-weight: 400;
color: #666666;
text-align: left;

}
.headi1{
   color: #999999;
font-family: Poppins;
font-size: 12px;
font-weight: 500;
line-height: 12px;
letter-spacing: 0.03em;
text-align: left;

}
.numb1{

   color: #1A1A1A;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    margin-top: -5px;
    

}
.price_total{
   
font-family: Poppins;
font-size: 18px;
font-weight: 400;
margin-top: 10px;
text-align: left;
color: #1A1A1A;

}

.order_summary {
    border: 1px solid #E6E6E6;
    border-radius: 10px;
    width: 56%;
    height: 286px;
    
}

.order_summary .order_head {
    border-bottom: 2px solid #E6E6E6;
    padding: 12px 16px;
    display: flex;
    gap: 18px;
    justify-content: space-between;
    align-items: center;
  }
  .heading1{
  
    color: #999999;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
 
margin-top: -0rem;
    text-align: left;
    

  }

.progress_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 50px auto;
    width: 100%;
    position: relative;

}
.progress_step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 25%;
   
}

.progress_step .circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #b3b3b3;
}

.progress_step p {
    margin: 10px 0 5px;
    color: #d97360;
    font-weight: 500;
    line-height: 21px;
    align-items: center;
    font-family: Poppins;
    font-size: 14px;
}

.progress_step small {
    color: #333333;
    font-weight: 500;
    line-height: 21px;
    align-items: center;
    font-family: Poppins;
    font-size: 14px;
}

.progress_step.active .circle {
    background-color: #d97360;
    color: white;
    
}

.progress_step.active small {
    color: #7c7c7c;
    font-weight: 400;
    line-height: 21px;
    align-items: center;
    font-family: Poppins;
    font-size: 14px;
}

.progress_container::before {
    content: '';
    position: absolute;
    top: 18px;
    /* Align with the middle of the circle */
    left: 12.5%;
    /* Start 12.5% into the container (middle of the first step) */
    width: 75%;
    /* Extend the line across 75% of the container */
    height: 4px;
    background-color: #f2f2f2;
    /* Line color for inactive steps */
    /* z-index: -1; */
 
}

.progress_step.active+.progress_step::before {
    content: '';
    position: absolute;
    top: 18px;
    /* Align with the middle of the circle */
    left: -42%;
    /* Ensure the line stretches to the previous step */
    width: 82%;
    /* Fill the space between two steps */
    height: 4px;
    background-color: #d97360;
    /* Line color for active steps */
    /* z-index: -1; */
}

.progress_step:first-child::before {
    content: none;
    /* Don't add a line before the first step */
}



.orderSummary {
    width: 100%;
   
}

.ordertable {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
 
}


.ordertable td {
    padding: 12px 15px;
    text-align: left;
    font-size: 14px;
    font-family: Poppins;
    font-weight: 400;
    color: #1a1a1a;
}

.ordertable th {
    background-color: #FAF3E0;
    padding: 12px 15px;
    text-align: left;
    font-size: 12px;
    font-family: Poppins;
    font-weight: 400;
    color: #4D4D4D;
}

.orderbuttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    
}

.productalign {
    display: flex;
    align-items: center;
    gap: 8px;
   
}


.order_rejection {
    display: flex;
    align-items: center;
    justify-content: space-between;
  
}

.rejection {
    width: 40%;
    border: 1px solid #E6E6E6;
    border-radius: 6px;
    padding: 10px 20px;
    font-size: 14px;
    
}

.trackOrderBtn,
.invoiceBtn {
    padding: 9px 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.trackOrderBtn {
    background-color: #fff;
    color: #C86550;
    border: 1px solid #C86550;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.trackOrderBtn:hover {
    background-color: #C86550;
    color: #fff;
    transform: scale(1.1);
}

.invoiceBtn {
    background-color: #C86550;
    color: white;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.invoiceBtn:hover {
    background-color: #fff;
    color: #C86550;
    border: 1px solid #C86550;
    transform: scale(1.1);
}
.mobile_order{
    display: none;
}


@media (max-width: 769px) {
    
/* .orderSummary {
    display: none;
} */
    .myprofile {
        width: 100%;
    }
    .profilemenu_container1__jaZBK {
        margin-left: 0px;
    }
   
}

@media (max-width: 540px) {
    .orderSummary {
    display: none;
}
    .progressmobile{
        flex-direction: column;
    }
    
    .progress_container{
        justify-content: space-between;
        align-items: flex-start;
        margin: 50px auto;
        width: 80%;
        position: relative;
        flex-direction: column;
        gap: 48px;
    }
    .progress_container::before {
        content: '';
        position: absolute;
        top: 18px;
        left: 6.5%;
        width: 4px;
        height: 91%;
        background-color: #f2f2f2;
    }
    .progress_step {
        flex-direction: row;
            align-items: center;
        position: relative;
        width: 100%;
        gap: 20px;
    }
    .progress_step.active+.progress_step::before {
        content: '';
        position: absolute;
        top: -54px;
        left: 6%;
        width: 4px;
        height: 114%;
      
    }
    .mobile_order{
        width: 100%;
        display: block;
    }
    .mobile_orderSummary{
     
        width: 100%;
        border: 1px solid #0000001A;
        
    border-radius:10px ;
    }
    .mobile_table{
        width: 100%;
     
       margin: 20px;
     
        border-radius: 20px;
      
    }
    .st4{
      
        font-family: Poppins;
        font-size: 20px;
        font-weight: 500;
        line-height: 50px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #000;

        
    }
    .moklist{
        display: flex;
        gap: 10px;
    }
    .productImage{
        width: 70px;
        height: 62.64px;
    }
    .second_class{
        gap: 15px;
        display: flex;
    }
    .menustart{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    }
    .mobilename{
text-align: left;
padding-right: 10%;
font-family: Poppins;
font-size: 14px;
font-weight: 400;
line-height: 21px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

    }
    .size_mobile{

font-family: Poppins;
font-size: 10px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0.03em;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

    }
    .size_mobile1{
        font-family: Poppins;
font-size: 10px;
font-weight: 600;
line-height: 15px;
letter-spacing: 0.03em;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

    }
}
@media (max-width: 1012px) {
    .ordetails_status .status span {
        font-size: 14px;
    }
    
    .rejection {
        width: 86%;
    }

    .order_rejection {
        flex-direction: column;
        margin-top: 20px;
    }

    .promenu {
        display: none;
    }

    .myprofile {
        padding: 0px 0px;
    }

    .ordetailsContainer {
        border: none;
    }

    .ordetailsaddress,
    .address_container {
        flex-direction: column;
        width: 100%;
        padding: 0px 0px;
    }

    .order_summary {
        width: 100%;
    }

   /*  .progress_container {
        display: none;
    }
 */




    .ordertable th,
    .ordertable td {
        padding: 18px 8px;
        vertical-align: top;
    }

    .ordertable {
        margin-bottom: 0px;
    }

    .ordetailsheader {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 20px 20px;
        border-bottom: none;
        flex-wrap: wrap;
        gap: 20px;
        flex-direction: column-reverse;
    }

    .ordetailsheader h2 {
        font-size: 20px;
        margin: 0px;
    }

    .ordetails_status {
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
    }

    .ordetails_status .status {
        font-size: 14px;
    }

    /* .productalign {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    } */

    .editButton {
        background-color: transparent;
        color: #d87156;
        border: none;
        padding: 0px 0px;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        text-decoration-line: underline;
    }

    .orderbuttons {
        justify-content: center;
        margin-top: 20px;
    }
}

@media (min-width: 768px) and (max-width: 1083px) {
    .myprofile {
     
        gap: 20px;
    }

    .ordetailsaddress {
        flex-direction: column;
        width: 100%
    }
}

/* @media (min-width: 1024px) and (max-width: 1440px) {
    .myprofile {
        padding: 40px 30px;
        gap: 20px;
    }

    .progress_container {
        width: 100%;
    }
} */