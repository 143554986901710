.container{
    height: 100%;
    width: 100%;
  background-color: #ffffff;
   justify-content: center;
   align-items: center;
   display: flex;

}
.section{
    height: 70%;
    width: 95%;
  
    display: flex;
    justify-content: center;
    justify-content: space-between;
    align-items: center;
    margin-top: 5rem;
    margin-bottom: 4rem;
  
}
.box1{
    height: 70%;
    width: 30%;
  
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.heading{
    font-family: Rubik;
    font-size: 20px;
    font-weight: 500;
line-height: 26px;
    text-align: center;
      }
      .head{
        font-family: Rubik;
        font-size: 16px;
        font-weight: 400;
    line-height: 20.8px;
        text-align: center;
        margin-top: -0.5rem;
          }
          @media  (max-width: 635px){
            .section{
                height: 70%;
                width: 95%;
              
                display: flex;
                justify-content: center;
                justify-content: space-between;
                align-items: center;
                flex-direction: column;
                margin-top: 1rem;
                margin-bottom: 1rem;
            }
            .heading{
                font-family: Rubik;
                font-size: 15px;
                font-weight: 500;
            line-height: 20px;
                text-align: center;
                  }
                  .head{
                    font-family: Rubik;
                    font-size: 12px;
                    font-weight: 400;
                line-height: 15.8px;
                    text-align: center;
                    margin-top: -0.5rem;
                      }
                      .box1{
                        height: 70%;
                        width: 50%;
                      
                        justify-content: center;
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                    }
          }