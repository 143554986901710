.container{
    height: 100%;
    width: 100%;
  background-color: rgb(248, 248, 248);
   justify-content: center;
   align-items: center;
   display: flex;
}

.section {
    height: 100%;
    width: 95%;
    display: flex;

flex-wrap: wrap;

   justify-content: center;
    align-items: center;
    margin-top: 5rem;
    margin-bottom: 4rem;

    gap: 15px;
    
 
}
.title{
    font-family: Rubik;
    font-size: 26px;
    font-weight: 500;
    line-height: 38px;
    text-align: center;
}
.button_3{
    padding: 10px 32px;
    color: #D0634F;
    background-color:#FAF3E0;
    font-family: Rubik;
font-size: 14px;
font-weight: 600;
/* line-height: 24px; */
    border: none;
    width: fit-content;
    transition: 0.3s ease-in;
    margin-left: 0px;
    border-radius: 24px;
    margin-top: 1rem;
   cursor: pointer;
    border: 2px dotted #D0634F ;
}
.button_3:hover{
    background-color:  #D0634F;
    color: #FAF3E0;
    border: 2px dotted #FFFFFF ;
   
} 
.box1 {
    height: 353.46px;
    width: 261px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    background-color: #FAF3E0;
    border-top-left-radius: 140px;
    border-top-right-radius: 140px;
    transition: transform 0.3s ease; /* Smooth transition for zoom effect */
}

.box1:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
}
.box2:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
}.box3:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
}.box4:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
}.box5:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
}.box6:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
}
.box2{
    height: 353.46px;
    width:261px;
  
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    background-color: #FAF3E0;
   border-top-left-radius:140px ;
   border-top-right-radius: 140Px ;
   background-image: url(category-13.png);
   background-size: 100%;
   background-repeat: no-repeat;
   transition: transform 0.3s ease;
   cursor: pointer;
}
.box3{
    height: 353.46px;
    width:261px;
    transition: transform 0.3s ease;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    background-color: #FAF3E0;
   border-top-left-radius:140px ;
   border-top-right-radius: 140Px ;
   background-image: url(category-12.png);
   background-size: 100%;
   background-repeat: no-repeat;
   cursor: pointer;
}
.box4{
    height: 353.46px;
    width:261px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    background-color: #FAF3E0;
   border-top-left-radius:140px ;
   border-top-right-radius: 140Px ;
   background-image: url(lookbook-14.png);
   background-size: 100%;
   background-repeat: no-repeat;
   transition: transform 0.3s ease;
   cursor: pointer;
}
.box5{
    height: 353.46px;
    width:261px;
  
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    background-color: #FAF3E0;
   border-top-left-radius:140px ;
   border-top-right-radius: 140Px ;
   background-image: url(category-11.png);
   background-size: 100%;
   background-repeat: no-repeat;
   transition: transform 0.3s ease;
   cursor: pointer;
}
.heading{
    font-family: Rubik;
    font-size: 29px;
    font-weight: 700;
    line-height: 38px;
color: rgb(255, 255, 255);
    text-align: center;
      }
      .head{
        font-family: Rubik;
        font-size: 29px;
        font-weight: 400;
    line-height: 38px;
        text-align: center;
        margin-top: -0.5rem;
          }
          @media (max-width: 1437px) {
            .box1{
                height: 315.46px;
                width:231px;
            }
            .box2{
                height: 315.46px;
                width:231px;
            }
            .box3{
                height: 315.46px;
                width:231px;
            }
            .box4{
                height: 315.46px;
                width:231px;
            }
            .box5{
                height: 315.46px;
                width:231px;
            }
          }
          @media (max-width: 1285px) {
            .box1{
                 height: 255.46px;
                width:191px;
            }
            .box2{
                height: 255.46px;
                width:191px;
            }
            .box3{
                height: 255.46px;
                width:191px;
            }
            .box4{
                height: 255.46px;
                width:191px;
            }
            .box5{
                height: 255.46px;
                width:191px;
            }
          }
          @media (max-width: 1070px) {
            .container{
                display: none;
            }
          }
          