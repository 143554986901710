/* myCart.module.css */
.container {
  padding: 20px;
  background-color: #f5f5f5;
}

.cartItem {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.cartSummary {
  margin-top: 20px;
}

.viewCartButton, .shopMoreButton {
  padding: 10px 20px;
  margin: 10px;
  border: none;
  background-color: #f68b1e;
  color: white;
  cursor: pointer;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}
