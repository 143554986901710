.fullhome{

 
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  background-color: #f6f5f5;
  background-image: url(image22.png);
  background-repeat: no-repeat;
  background-size: cover; /* Ensures the image covers the container */
  background-position: left; /* Positions the image to overflow on the left */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06); /* Adds a subtle shadow */
}

  .section{
    height: 100%;
    width: 100%;

    justify-content: center;
    display: grid;
    align-items: center;
  

  }
  .box {
    height: 50%;
    width: 90vw;
    margin-top: 20rem;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    margin-bottom: 4rem;

  }
  .left{
    height: 90%;
    width: 95%;
  }
  .right{
    height: 90%;
    width:5%;

    display: flex;
  justify-content: flex-end;
 
  }
  .heading{
font-family: Rubik;
font-size: 56px;
font-weight: 500;
line-height: 72.8px;
  }
  .text{
    font-family: Rubik;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
      }
      .hero_button {
        padding: 10px 32px;
        color: #FFFFFF;
        background-color: #D0634F;
        font-family: Rubik;
        font-size: 14px;
        font-weight: 600;
        width: fit-content;
        transition: 0.3s ease-in;
        margin-left: 0px;
        border-radius: 24px;
        margin-top: 1rem;
       cursor: pointer;
        /* Add dotted border */
        /* border: 2px dotted #FFFFFF; Adjust color and thickness as needed */
        border: 1px dashed #fff;
    }
    .hero_button:hover{
        background-color: rgba(255, 255, 255, 0.9);
        color: #D0634F;
        border: 1px dashed #D0634F;
    }
    .Wh_icon{
margin-top: 15rem;
    }
    @media  (max-width: 1440px){
        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
          
            background-image: url(image22.png);
            background-repeat: no-repeat;
            
            background-position: left;
       
          }
        }
        @media  (max-width: 635px){
          .section{
            height: 180px;
            width: 100%;
            justify-content: center;
            display: grid;
            align-items: center;
          
        
          }

          .right{
            height: 310%;
            width:5%;
        
            display: flex;
          justify-content: flex-end;
         
          }
          .hero_button {
            font-size: 10px;
          }
          .container {
            height:fit-content;
            background-size: cover;
            background-position: center;
            background-image: url(image22.png);
          }

          .left{
            height:175%;
            width: 95%;
          }
          .box {
            height: 50%;
            width: 90vw;
            margin-top: 5rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            justify-content: center;
            justify-content: space-between;
            margin-bottom: 4rem;
         
          }
    
          .Wh_icon img{
           height: 30px;
                }
              .heading{
                font-family: Rubik;
                font-size: 20px;
                font-weight: 500;
                line-height: 27.8px;
              
                  }

                .heading input {
                  color:#fff
                }
              .text{
                font-family: Rubik;
                font-size: 12px;
                font-weight: 400;
                line-height: 5px;
           
                  }
                  .hero_button{
                    padding: 5px 10px;
                    color: #FFFFFF;
                    background-color:#D0634F;
                    font-family: Rubik;
                font-size: 12px;
                font-weight: 600;
                /* line-height: 24px; */
                  
                    width: fit-content;
                    transition: 0.3s ease-in;
                    margin-left: 0px;
                    border-radius: 24px;
                    margin-top: 0rem;
                }
                .hero_button:hover{
                    background-color: rgba(255, 255, 255, 0.9);
                    color: #D0634F;
                    border: 1px solid #D0634F;
                }
        }


      