.container{
    height: 100%;
    width: 100%;
    background-color: #FAF3E0;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4rem;
    
    
    
}
.contant{
    margin-top: 1rem;
    width: 80%;
    color: rgb(0, 0, 0);
    height: 80%;
    justify-content: space-between;
    gap: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items:  baseline;
    padding-bottom: 5rem;
 
   
 
  
   
   
}
.section{

    height: 100%;
    width: 185px;

}
.section1{

    height: 100%;
    width: 265px;


}

.logo1{
    font-family: Rubik;
    font-weight: 600;
    font-size: 38.82px;
    line-height: 45.46px;
}
.mainImg{
    width: 130px;
}
.heading{
    font-family: Rubik;
    font-weight: 600;
    font-size: 20px;
    line-height: 23.42px;
}
.pages{
    display: grid;
    margin-top: 0.5rem;
}
.page{
    margin-top: -1.5rem;
}   

.pageLink{
    font-family: Rubik;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.23px;
    letter-spacing: 3%;
    color: #040202;
    text-decoration: none;
    margin-top: 15px;
    cursor: pointer;
}
.contant1{
    width: 70%;
}
.main{
    width: 100%;
}
.contant2{
    color: #000000;
    font-family: Rubik;
    font-weight: 400;
    font-size: 14px;
    line-height: 39.23px;
    letter-spacing: 3%;
    color: #F4F4F4;
    text-decoration: none;
    margin-bottom: 2rem;
}
.icons{
    display: flex;
    gap: 15px;
    margin-top: 20px;
}
@media (max-width: 1000px) {
    .contant{
        margin-top: 5rem;
        width: 70%;
    }

}
@media (max-width: 772px) {
    /* .contant{
        width: 50%;
    } */
}
@media (max-width: 482px) {
    .contant{
        width: 85%;
        margin-top:0px ;
    }
}