.mobileFilterContainer {
    display: flex;
    flex-direction: column;
 justify-content: center;
 align-items: center;
    width: 100%;
  height: 100%;

  
  }
  .categories{
    display: flex;
    width: 100%;
    height: 100%;
   
  }
  .clrear{


  font-family: Public Sans;
font-size: 16px;
font-weight: 600;
line-height: 20px;
letter-spacing: 0.005em;
text-align: right;
color: #C86550;



  }
  .global{
  width: 90%;
  display: flex;
  align-items: center;
    margin-bottom: 2rem;
    margin-top: 2rem;
    gap: 10px;
  }
  .glob{
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .productshows{
    font-family: Public Sans;
font-size: 12px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0.005em;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #000;

  }
  .mainspace{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
  }
  .mess{
   color: #7E7E7E;
font-family: Poppins;
font-size: 14px;
font-weight: 400;
line-height: 21px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

  }

  
.colorCircle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
  
  }

  .categoriesSection {
   background-color: #FAF3E0;
  flex: 1;
  height:100vh;
  }
  .categoriesSection ul {
    list-style: none;
    padding: 0;
    width: 100%;
    height: 45px;
   margin-top: 0px;

  }

  .dropdownTrigger {
    background-color: #007bff;
    width: 100px;
    display: flex;
    justify-content: space-between;

  }
  
  .categoriesSection li {
    
   
    font-size: 15px;
    font-family: Poppins;
    font-weight: 500;
    line-height: 60px;
    color: #7E7E7E;
padding-left: 20px;




  }
  .categoriesSection li:hover {
    background-color: #f4f1f1; /* Hover effect */

  }
  
  /* Style for selected (active) li */
  .categoriesSection li.active {
    background-color: #ffffffbd; /* Example active background color */
    color: #463F3F; /* Change text color when active */
  }
  .All{
    
    font-size: 18px;
    font-family: Poppins;
    font-weight: 500;
  
  color: #463F3F;
  

  }
  .value{
    font-size: 18px;
    font-family: Poppins;
    font-weight: 500;

    color: #0d0d0d;
  }
  .lable{
    font-size: 18px;
    font-family: Poppins;
    font-weight: 500;
 
  color: #7E7E7E;
  }
  .customCheckbox{
    width: 18px;
  height: 18px;
  background-color: #fff; /* Unchecked box color */
  border: 2px solid #007bff;
  }
  
  .filterOptionsSection {
    flex: 2;
    padding: 20px;
 
    
  }
  .stand{    flex: 2;}

 
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  .clearButton {
    background: none;
    color: #d85454;
    border: none;
    font-size: 18px;
    cursor: pointer;
    font-family: Public Sans;
  }
  
  .filterList {
    list-style: none;
    padding: 0;
    margin: 0;
  
  }
  
  .filterItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
   
  }
  
  .filterItem label {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .filterFooter {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    z-index: 1000;
    background-color: #f8f8f8;
  
    justify-content: center;
    display: flex;
    align-items: center;
    border-top-left-radius: 25px; /* Rounded corners */
    border-top-right-radius: 25px; /* Rounded corners */
    gap: 50px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1); /* Background shadow */
 
   
  }
  .cancelButton{
    padding: 10px 32px;
    color: #000;
    background-color:white;
    font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  /* line-height: 24px; */
    border: none;
    width: fit-content;
    transition: 0.3s ease-in;
    margin-left: 0px;
    border-radius: 10px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  }
  .cancelButton:hover{
    background-color: rgba(255, 255, 255, 0.9);
    color: #D0634F;
    border: 1px solid #D0634F;
  }
  .applyButton{
    padding: 10px 32px;
    color: #ffffff;
    background-color:#D0634F;
    font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  /* line-height: 24px; */
    border: none;
    width: fit-content;
    transition: 0.3s ease-in;
    margin-left: 0px;
    border-radius: 10px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  }
 
  @media (max-width: 400px) {
    .categoriesSection li {
    
   
        font-size: 15px;
        font-family: Poppins;
        font-weight: 500;
        line-height: 60px;
        color: #7E7E7E;
    padding-left: 10px;
    
    
    
      }
  }
  @media (max-width: 340px) {
    .All{
    
        font-size: 15px;
        font-family: Poppins;
        font-weight: 500;
      
      color: #463F3F;
    
      }
      .value{
        font-size: 15px;
        font-family: Poppins;
        font-weight: 500;
     
      color: #0d0d0d;
      }
      .lable{
        font-size: 15px;
        font-family: Poppins;
        font-weight: 500;
      
      color: #7E7E7E;
      }
      .customCheckbox{
        width: 15px;
      height: 15px;
      background-color: #fff; /* Unchecked box color */
      border: 2px solid #007bff;
      }
  }