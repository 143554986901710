.profilebodyContainer {
    background-color: #fff;
   /*  padding: 24px; */
    border: 1px solid #eee;
    border-radius: 8px;
    width: 100%;
    /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); */
}

.profileheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    border-bottom: 1px solid #eee;
}

.profileheader h2 {
    font-size: 24px;
    color: #333;
    /* margin: 0px; */
}

.editButton {
    background-color: #d87156;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;
}

.profilebodyContent {
    display: flex;
    justify-content: center;
    align-items: center;
    padding:20px;
}

.profilebodyDetails {
    display: flex;
    flex-direction: column;
    margin-right: 32px;
    width: 70%;
}

.profilebodyDetails label {
    display: block;
    font-size: 14px;
    color: #333;
    margin-bottom: 8px;
}

.profilebodyDetails input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.profilebodyDetails input:disabled {
    background-color: #f3f3f3;
}

.savechanges {
    background-color: #d87156;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 16px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 16px;
    width: 100%;
}

.profilebodyImage {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
}

.profilebodyImage .avatar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 16px;
}

.changeImageButton {
    background-color: transparent;
    border: 1px solid #d87156;
    color: #d87156;
    border-radius: 5px;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;
}


/* Form css */
.profileform {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    margin: 4% 10%;
  }
  
  .profilerow {
    display: flex;
    gap: 15px;
  }
  
  .profileformGroup {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .profileformGroup label {
    font-size: 14px;
    color: #555;
    margin-bottom: 8px;
  }
  
  .profileformGroup input,
  .profileformGroup select {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  
  .checkboxGroup {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .checkboxGroup input {
    width: 18px;
    height: 18px;
  }
  
  .buttonGroup {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .cancelButton {
    background-color: transparent;
    color: #C86550;
    border: 1px solid #C86550;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .saveButton {
    background-color: #C86550;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .cancelButton:hover {
    background-color: #C86550;
    color: white;
  }
  
  .saveButton:hover {
    background-color: #C86550;
  }
  

@media  (max-width: 768px){
  .profileheader{
      padding: 20px 20px;
  }
    .profileform{
        margin: 4% 0%;
    }
    .profilerow{
        flex-direction: column;
    }
    .profilebodyContainer {
        border: none;
    }
    .profilebodyDetails {
        width: 100%;
        margin-right: 0px;
    }
    .savechanges {
        width: 100%;
    }
    .profileheader h2 {
        font-size: 20px;
    }
}