.login_container {
    display: flex;
    align-items: flex-start;
}

.image_section {
    text-align: center;
    width: 56%
}

.image_section img {
    width: 100%;
    height: 100vh;
}

.form_section {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 48%
}



.form_section h2 {
    font-size: 30px;
    margin-bottom: 0px;
    color: #131118;
}

.form_section p {
    font-size: 16px;
    color: #666;
    margin-bottom: 40px;
}

form {
    display: flex;
    flex-direction: column;
}

.form_container label {
    font-size: 12px;
    margin-bottom: 5px;
    font-weight: bold;
    color: #6C7275;
}

.form_container input {
    padding-left: 8px;
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid #ccc;
    height: 40px;
}
.form_container {
    width: 60%;
}

.newuser {
    text-align: center;
    font-size: 14px;
    color: #131118;
}
.newuser p {
    font-size: 16px;
    color: #666;
    margin: 30px 20px 20px 20px;
}
.signup{
    text-decoration: none;
    color: #d9776a;
}
.separator {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 0px 60px 20px 60px;
}

.separator::before,
.separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #ccc;
}

.separator::before {
    margin-right: 10px;
}

.separator::after {
    margin-left: 10px;
}

.separator span {
    font-size: 14px;
    color: #666;
}

.form_container button {
    padding: 10px;
    background-color: #d9776a;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    /* height: 40px; */
}

.form_container button:hover {
    background-color: #d9776a;
    color: #fff;
}

.reactbutton button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: rgb(255, 255, 255);
    color: black;
    border: 1px solid #d9776a;
    margin-top: 10px;
    width: 100%;
}

.login img {
    margin-right: 10px;
}


@media  (max-width: 768px){
    .image_section {
        width: 100%;
        display: none;
    }
    .form_section {
        width: 100%;
        padding: 20px;
    }
    .form_section p{
        width: 100%;
    }
    .form_container {
        width: 100%;
    }
    .form_section h2 {
        font-size: 24px !important;
    }
    .separator{
        margin: 0px 20px 20px 20px;
    }
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    .form_container {
        width: 86%;
    }
    .form_section p{
        width: 100%;
        text-align: center !important;
    }
    .separator{
        margin: 0px 20px 20px 20px;
    }
  }
  
  @media (min-width: 1024px) and (max-width: 1440px) {
    .form_container {
        width: 86%;
    }
    .form_section  p{
        width: 100%;
        text-align: center !important;
    }
    .separator{
        margin: 0px 20px 20px 20px;
    }
  }
  
  /* @media (min-width: 1279px) and (max-width: 1440px) {
  } */