.container{
    width: 100%;
  background-color: rgb(248, 248, 248);
    display: none;
}

.brands{
    display: flex;
    align-items: center;
    justify-content: space-between;
 
}
/* .brands img{
    width: 90px;
    height: auto;
} */


.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0px 20px 0px;
    }
    
    .slider {
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;
    }
    
    .slidetrack {
    display: flex;
      -webkit-animation: scroll 25s linear infinite;
    animation: scroll 25s linear infinite;
    width: calc(350px * 46);
      gap: 10px;
      align-items: center;
   
    

    }
  
  

    .slide img {
        width: 100%;
      height: auto;
      }
      .title{
        font-family: Rubik;
        font-size: 26px;
        font-weight: 500;
        line-height: 38px;
        text-align: center;
    }
    .button_3{
        cursor: pointer;
        padding: 10px 32px;
        color: #D0634F;
        background-color:#FAF3E0;
        font-family: Rubik;
    font-size: 14px;
    font-weight: 600;
    /* line-height: 24px; */
        border: none;
        width: fit-content;
        transition: 0.3s ease-in;
        margin-left: 0px;
        border-radius: 24px;
        margin-top: 1rem;
        border: 1px solid #D0634F;
    }
    .button_3:hover{
        background-color:  #D0634F;
        color: #FAF3E0;
       
    }
      .box1{
        height: 353.46px;
        width:261px;
      
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        background-color: #FAF3E0;
       border-top-left-radius:140px ;
       border-top-right-radius: 140Px ;
    }
    .box2{
        height: 353.46px;
        width:261px;
      
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        background-color: #FAF3E0;
       border-top-left-radius:140px ;
       border-top-right-radius: 140Px ;
       background-image: url(category-13.png);
       background-size: 100%;
       background-repeat: no-repeat;
    }
    .box3{
        height: 353.46px;
        width:261px;
      
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        background-color: #FAF3E0;
       border-top-left-radius:140px ;
       border-top-right-radius: 140Px ;
       background-image: url(category-12.png);
       background-size: 100%;
       background-repeat: no-repeat;
    }
    .box4{
        height: 353.46px;
        width:261px;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        background-color: #FAF3E0;
       border-top-left-radius:140px ;
       border-top-right-radius: 140Px ;
       background-image: url(lookbook-14.png);
       background-size: 100%;
       background-repeat: no-repeat;
    }
    .box5{
        height: 353.46px;
        width:261px;
      
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        background-color: #FAF3E0;
       border-top-left-radius:140px ;
       border-top-right-radius: 140Px ;
       background-image: url(category-11.png);
       background-size: 100%;
       background-repeat: no-repeat;
    }
    .heading{
        font-family: Rubik;
        font-size: 29px;
        font-weight: 700;
        line-height: 38px;
    color: rgb(255, 255, 255);
        text-align: center;
        cursor: pointer;
          }
          .head{
            font-family: Rubik;
            font-size: 29px;
            font-weight: 400;
        line-height: 38px;
            text-align: center;
            margin-top: -0.5rem;
              }

    @-webkit-keyframes scroll {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(calc(-350px * 4));
        }
        }
        
        @keyframes scroll {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(calc(-350px * 4));
        }
        }
        
/* @media (max-width: 768px) {
    .container{
        width: 100%;
    }
    .container h2{
        font-size: 26px;
    }
    .slide img{
        width: 48px !important;
    }
} */
/* @media (max-width: 768px) {
    .slidetrack {
      gap: 0px;
    }
    .hide{
        display: none;
        visibility: none;
    }
    .container h2{
        padding: 1px 8px;
        font-size: 24px !important;
        line-height: 38px !important;
    }
    .container {
        padding: 20px  0px;
    }
    .slide img{
        width: 90% !important;
    }
    .container{
        width: 100%;
    }
    .wrapper{
      padding: 20px 0px 0px 0px;
    }
} */
@media (max-width: 1070px) {
    .container{
        display: block;
    }
    .box1{
        height: 255.46px;
       width:191px;
   }
   .box2{
       height: 255.46px;
       width:191px;
   }
   .box3{
       height: 255.46px;
       width:191px;
   }
   .box4{
       height: 255.46px;
       width:191px;
   }
   .box5{
       height: 255.46px;
       width:191px;
   }
  }
  
