.Container{

  display: flex;
 justify-content: center;
flex-direction: column;
justify-content: flex-start;
align-items: center;
width: 70%;

}
.button_con{
  justify-content: space-between;
  width: 100%;
  display: flex;

}
.body_button{
  padding: 10px 40px;
  background-color: white;
  border-radius: 5px;
  border: 1px #00000080 solid;
 
font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 24px;


}
.cartBox{
  display: none;

}
.mobilecontainer{
  display: none;
}
.cartContainer {
  display: flex;
justify-content: center;
gap: 30px;
justify-content: space-between;
flex-direction: column;
  width: 100%;
 
}

.cartItems {
  width: 100%;
 
}

.cartTable {
  width: 100%;
  border-collapse: collapse;
}
.heading{
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  
}
.cartbutton {
    background-color:#C86550; /* Blue background */
    color: white; /* White text */
    border: none; /* No border */
    padding: 12px 24px; /* Padding for button size */
    border-radius: 8px; /* Rounded corners */
    font-size: 16px; /* Font size */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth transition on hover */
    width: 150px;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.head{
 font-family: Poppins;
  font-weight: 400;
  font-size: 16px; 
  color: #232323;
}
.productDetailes{
  border-top: #9F9F9F 2px solid;
}

.cartTable th,
.cartTable td {
  padding: 15px;
  text-align: left;

}
.cartTable td{
  font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 24px;
color: #232323;

}
.cartTable th{
  font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 24px;


}
.opreter{
  border: none;
  background-color: transparent;
  color: #9F9F9F;
}
.num{
color: #9F9F9F;
}

.productImage{
  width: 100px;
  height: 91px;
  cursor: pointer;
}

.quantityControls {
  display: flex;
  align-items: center;
 
}
.Controls{
   display: flex;
  align-items: center;
  border: 1px solid #9F9F9F;
}

.quantityControls button {
  padding: 5px;
  margin: 0 5px;
}

.removeButton {
  background-color: transparent;
  border: none;
  color: #ff0000;
  cursor: pointer;
  margin-top: 10px;
  
}

.addMoreItems {
  margin-top: 20px;
}

.more {
  color: #C86550;
  border-bottom: 2px solid #C86550;
  text-decoration: none; /* Remove default underline */
  font-weight: bold;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 30px;
  cursor: pointer;
}


.orderSummary {
  width: 25%;
  border: 1px solid #ddd;
  padding: 40px;

 
}


.Order {

 
  font-size: 24px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 32px;
  color: #17183B;
  margin-top: -5px;
}

.summaryItem {
  display: flex;
  justify-content: space-between;

}
.priceSummary{
  font-family:Poppins;
  font-size: 16px;
  line-height: 50px;
  color: #17183B;
  font-weight: 400;
}
.FreeSummary{
  font-family:Poppins;
  font-size: 16px;
  line-height: 50px;
  color: #3AA39F;
  font-weight: 400;
}
.bold{
 font-family:Poppins;
  font-size: 16px;
  line-height: 50px;
  color: #17183B;
  font-weight: 600;
}
.buttondelat{
  display: flex;
  justify-content: center;
  align-items: center;
 
  gap: 10px;
}

.summaryTotal {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 1.2em;
}

.checkoutButton {
  width: 100%;
  padding: 10px;
  background-color: #e26d5c;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.checkoutButton:hover {
  background-color: #d65f54;
}
@media (max-width: 1262px) {

.cartContainer {
  display: flex;
justify-content: center;
gap: 30px;
justify-content: space-between;

  width: 100%;
 
}
.orderSummary {
  width: 30%;
  border: 1px solid #ddd;
  padding: 40px;
  
 
}
}
@media (max-width: 1072px) {
  .button_con{
    margin-top: 0px;
    width: 90%;
    margin-bottom: 1rem;
  }

  .cartContainer {
    display: none;
  }
  .mobilecontainer{
    display: block;
    width: 100%;
   
  
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mobileheading{
    color: #C86550;
  text-decoration: underline ;
  text-decoration-color: #C86550 ;
  font-weight: bold;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 30px;
text-align: right;
width: 90%;
color: #d65f54;



  }
  .rast{
    justify-content: flex-end;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    text-align: right;
  }
  .mobilecontant1{
    width: 80%;
   
    border: 1px solid #E6E6E6;
   padding: 20px 20px 0px 20px;
    
    border-radius: 20px;

  }
  .mobilecontant{
    border-bottom: 1px solid #e6e6e6;

    margin-top: 1rem;
  }


  .mobilebox{
    display: flex;
    justify-content: space-between;
 
   height: 50px;
 
  }
  .mobilebox1{
    display: flex;
    justify-content: space-between;


  }
}
@media (max-width: 1052px) {
/* .cartContainer {
  flex-wrap: wrap;
  align-items: center;
    display: flex;
justify-content: center;
} */
.cartItems {
  width: 90%;
 
}
.orderSummary {
   width: 35%;
}
}
/* @media (max-width: 850px) {
.orderSummary {
   width: 45%;
}
}
@media (max-width: 718px) {
  .cartContainer {
display: none;
  }
  .Container{
    display: grid;
  }
  .cartBox{
  display: block;
}
} */

@media (max-width: 768px) {
  .Container{
    width: 100%;
  }
  .body_button{
    padding: 9px 15px;
    font-size: 12px;
  }
}