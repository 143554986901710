
.myprofile{
    display: flex;
    gap :40px;
    padding: 40px 60px;
}

.changePassContainer {
    background-color: #fff;
   /*  padding: 24px; */
    border: 1px solid #eee;
    border-radius: 8px;
    width: 100%;
    /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); */
}

.changePassheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    border-bottom: 1px solid #eee;
}

.changePassheader h2 {
    font-size: 24px;
    color: #333;
    /* margin: 0px; */
}

.editButton {
    background-color: #d87156;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;
}

.changePassbodyContent {
    display: flex;
    justify-content: center;
    align-items: center;
    padding:20px;
}

.changePassbodyDetails {
    display: flex;
    flex-direction: column;
    margin-right: 32px;
    width: 70%;
}

.changePassbodyDetails label {
    display: block;
    font-size: 14px;
    color: #333;
    margin-bottom: 8px;
}

.changePassbodyDetails input {
   /*  width: 70%; */
    padding: 10px;
    font-size: 14px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.changePassbodyDetails input:disabled {
    background-color: #f3f3f3;
}

.changePass {
    background-color: #d87156;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 16px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 16px;
    width: 100%;
}

.changePassbodyImage {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
}

.changePassbodyImage .avatar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 16px;
}

.changeImageButton {
    background-color: transparent;
    border: 1px solid #d87156;
    color: #d87156;
    border-radius: 5px;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;
}

@media  (max-width: 768px){
    .promenu{
        display: none;
    }
    .myprofile{
        padding: 0px 0px;
    }
    .changePassbodyContainer {
        border: none;
    }
    .changePassbodyDetails {
        width: 100%;
        margin-right: 0px;
    }
    .savechanges {
        width: 100%;
    }
    .changePassheader h2 {
        font-size: 20px;
    }
}