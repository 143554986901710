.myprofile {
  display: flex;
  gap: 40px;
  width: 70%;


}

.prodetailsContainer {
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 8px;
  width: 100%;
 

}

.profilebodyContent {
  display: flex;
  align-items: center;
  padding: 20px;

}

.profileheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  border-bottom: 1px solid #eee;

}

.profileheader h2 {
  font-size: 24px;
  color: #333;
}

.editButton {
  background-color: #d87156;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
}

.prodetails {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #eee;
  width: 70%;

 
}

.prorow {
  display: flex;
  gap: 20px;

}

.procustomer {
  width: 30%;

}

.procname {
  width: 60%;
}

.orderbuttons {
  margin-top: 20px;
  display: flex;
  justify-content: left;
  gap: 10px;
  padding: 0px 15px 15px 15px;

}

.trackOrderBtn {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #fff;
  color: #C86550;
  border: 1px solid #C86550;
  font-size: 14px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.trackOrderBtn:hover {
  background-color: #C86550;
  color: #fff;
  transform: scale(1.1);
}

.card {
  padding: 15px;
  border: 2px solid #ccc;
  border-radius: 8px;
  background-color: #ffffff;
  margin-left: 40px;
  margin-top: 40px;
  width:312px;
  margin-bottom: 40px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-weight: 500;
  border-bottom: 2px solid #eee;
  padding: 15px;

  color: #999999;
}

.default {
  color: #C86550; /* Red color for 'DEFAULT' */
  font-size: 12px;
  font-weight: bold;
}

.content {
  margin-bottom: 15px;
  padding: 0px 15px;
}

.name {
  font-size: 16px;

  color: #333;
  font-family: Poppins;

font-family: Poppins;

font-weight: 400;
line-height: 24px;
text-align: left;

}
.plus{
margin-left: 5px;
}

.address {
  font-size: 14px;
  color: #555;
  margin: 5px 0;
}

.phone {

  color: #333;
  font-family: Poppins;
font-size: 14px;
font-weight: 300;
line-height: 21px;
text-align: left;

}

/* Grid container for the address cards */
.gridContainer {
  display: flex;
  grid-template-columns: repeat(2, 1fr); /* Two address cards per row */
  gap: 20px; /* Gap between cards */
  flex-wrap: wrap;
 
}
.nodata{
  justify-content: center;
  align-items: center;
  display: flex;
  height: 70%;
}
.noadd{
  font-family: Poppins;
font-size: 14px;
font-weight: 500;
line-height: 14px;
letter-spacing: 0.03em;

color: #999999;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .card {
    background-color: transparent;
  }
  .prodetails {
    background-color: transparent;
  }
  .profileheader {
    border-bottom: none;
    margin-bottom: 20px;
    margin-top: 30px;
    margin-left: -10px;
  }
  /* .trackOrderBtn{
    border: none;
    background-color: #ffffff;
  } */
  .prodetailsContainer {
    border: none;
    border-radius: 0px;
    background-color: transparent;
  }

  .editButton {
  background-color: transparent;
  color: #d87156;
  border: none;
  border-radius: 5px;
  padding: 8px 0px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
}
  .pheading{
    display: none;
  }
  .myprofile {
    width: 100%;
  }
  .gridContainer {
    grid-template-columns: 1fr; /* One address card per row on smaller screens */
  flex-direction: column;
    justify-content: center;
  }
  .card {
    padding-left: 0px;
    padding-top: 0px;
    margin-left: 0px;
    margin-top: 0px;
    width: 90%;
    margin-bottom: 0px;
    border: none;
  }
}

@media (max-width: 1023px) {
  /* .myprofile {
    padding: 0px 60px;
  } */
}

@media (max-width: 1440px) {
  /* .myprofile {
    padding: 40px 60px;
  } */
}
@media (max-width: 338px) {
  .profileheader h2 {
    font-size: 20px;
    color: #333;
  }
}
