.container1{
    position:relative;
    top: 0px;
    margin-left: 5%;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}


.main {
display: flex;
justify-content: center;

width: 90%;
gap: 20px;
}

.profileMenu {
    width: 246px;
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 8px;
    height: 100%;
    
}
.profileContent{

    width: 80%;

}

.userInfo {
    display: flex;
    align-items: center;
    padding: 16px 16px;

}
.userName{
    font-family: Poppins;
    font-weight: 400;
    font-size: 15px;
    line-height: 27px;
    color: #1A1A1A;
}
.profileAvatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 8px;
}

.profileuserName {
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

.profilemenuList {
    list-style-type: none;
    padding: 0;
    margin: 0px;
  
   
}

.profilemenuItem {
    display: flex;
    align-items: center;
    padding: 18px 16px;
 justify-content: space-between;
 align-items: center;
    color: #aaa;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
 
    font-family: Poppins;
    font-weight: 400;
}

.profilemenuItem:hover {
    color: #000;
}

.profileactive {
    background-color: #FAF3E0;
    border-left: 4px solid #C86550;
    color: #000;
    font-weight: bold;
    padding: 8px 16px;
    font-size: 14px;

}
.poin{
    align-items: flex-start;
    text-align: left;
    width: 238px;
   
}
.profileicon {
    margin-right: 8px;

    justify-content: center;
    align-items: center;
  
}
@media  (max-width: 769px){
    .container1{
        margin-left: 0%;
    }
    .profileMenu {
        display: none;
    }  
    .profileContent{

        width: 100%;
    }
    .main {
        width: 100%;
    }
}


