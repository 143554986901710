.myprofile{
    display: flex;
    gap :40px;
    padding: 40px 60px;
}



@media  (max-width: 768px){
    .profileheader{
        padding: 20px 20px;
    }
    .promenu{
        display: none;
    }
    .myprofile{
        padding: 0px 0px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .myprofile{
        padding: 0px 60px;
    }
}
/* @media (min-width: 1024px) and (max-width: 1440px) {
    .myprofile{
        padding: 0px 120px;
    }
} */