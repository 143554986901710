.container {
width: 100%;
display: block;
background-color: #FAF3E0;
height: 130px;
justify-content: center;
align-items: center;
display: flex;
/* position: relative; */
}


.mainHeader {
    margin: auto;
    width: 98%;
    height: 99px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
    
}

.mainHeader img {
    padding: 4px;
    width: 120px;
    height: auto; 
}

.searchContainer {
  position: relative;
  width: 100%;
  max-width: 200px;
}

.searchInput {
  /* width: 85%; */
  padding:  40px 10px 10px; 
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 25px;
   /* color: "green", */
}

.searchIcon {
  /* position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #888;
  font-size: 24px;  */
}

.mobile_profileIcon {
    display: none;
}


.navmenu {
    transition: 0.3s ease-in;
    color: #D85D5D;
    font-family: Rubik;
    text-decoration: none;
    font-size: 17px;
    font-weight: 400;
    line-height: 23.27px;
    letter-spacing: 0em;
    padding: 5px;
    z-index: 10;

}

.navmenu:hover,
.service:hover {
    color: #D85D5D;
}

.rightNav {
    display: inline-flex;
    /* margin-left: 150px; */
    transition: all ease-in 0.6s;
    gap: 35px;
    /* z-index: 1; */
    margin-left: 5rem;
   
    margin-top: 40px;
  
 
}
.icons{
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
 
    
   
}
.search img{
    size: 20px;
}

.mainHeader svg {
    display: none;
}
.profileicon svg{
  display: block;

}
.iconclose svg{
  display: block;
}
.dropdown {
    display: inline-block !important;
    margin-left: -2px;
    margin-top: 0px;
}
.dropdown1{
    display: inline-block !important;
    margin-left: -2px;
    margin-top: 0px;
} 


.page {
    position: relative;
}


.list {
    display: none;
    position: absolute;
    margin-left: -30px;
    background-color: transparent;
    justify-content: flex-end;
    margin-top: 0px;
    padding-top: 20px;
   
 
 
}
.contentus {
    border-top:2px solid #D85D5D ;
    background-size: 120%;
    background-repeat: no-repeat;
    margin-top: 13px;
    background-color: white;
    width: 180px;
 
 
}

.category1 {
    width: 50%;
    margin-right:30px;
  }
.title23{
font-family: Rubik;
font-size: 14px;
font-weight: 500;
line-height: 14px;
color: #D85D5D;
padding-left: 20px;
cursor: pointer;
width: auto;
padding-block: 5%;
}

.title23:hover{
    /* background-color:  #FAF3E0; */
    color: #272343;
}

.service {
    color: #D85D5D;
    font-weight: 600;
    transition: 0.3s ease-in;
    text-decoration: none;
    font-size: 14px;
    padding: 5px;
    text-transform: capitalize;
}

.pages:hover .list {
    display: flex;
    flex-direction: column;
}
.menuflex{
    display: flex;
    justify-content: center;
    align-items: center;
 
}
.mobilenav{
    display: none;
}
.hide{
    display: none;
}
.dropdownTrigger {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.submenu {
  margin-bottom: 10px;
 
}

.submenuList {
  padding-inline: 20px;
  width: 60%;
  position: absolute;
  background-color: #fff;
  color: #C4573A;
  left: 100%;
  top: 33px;
  display: none; 
  z-index: 10;
}

.submenu:hover .submenuList {
  display: block; 
}

.submenuItem {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.submenuItem:hover {
  color: black;
}




.hamburger {
    /* Style for the hamburger button */
    cursor: pointer;
  }
  
  .menu {
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    top: 0;
    left: 0;
    width: 344px;
    height: 100vh;
    background-color: #fff;
    box-shadow: -2px 0 5px rgba(0,0,0,0.5);
    z-index: 100;
    padding: 20px;
  }
  
  .menuHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #D9D9D9;
  

  }
  .closeButton img{
width: 24px;
height: 24px;
  }
  
  .menuTitle {
 color: #272343;
    font-family: Rubik;
font-size: 18px;
font-weight: 700;
line-height: 21.33px;


  }
  
  .closeButton {
    font-size: 18px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .menuItems {
    list-style: none;
    padding: 0;
    margin-top: 20px;
    width: 100%;
  }
 
  
  .menuItems li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 33.5px;
letter-spacing: 0.02em;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #C4573A;

  }
  .menuItems li {
    padding: 10px 0;
    cursor: pointer;
    position: relative;
  }
  
  .dropdownContent {
    display: block;
    padding: 8px;
    background-color: #f9f9f9;
    border-top: 2px solid #C4573A;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 10;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Add this line for shadow */
  }


@media (max-width: 1204px) {
    .navmenu {
        font-size: 16px;
        margin-left: 0px;
    }

    .mainHeader img {
        padding: 4px;
        width: 80px;
        height: auto;
       
       
    }

    .rightNav {
        gap: 20px;
        margin-left: 10px;
        margin-top: 20px;
       
    }
    .container {
        height: 90px;
    }
    .icons{
        margin-top: 20px;
       
    }
    .list {
        padding-top: 5px;
    }
    
}
@media (max-width: 1000px) {
    .navmenu {
        font-size: 12px;
        margin-left: 0px;
    }
}

@media (min-width: 1024px) and (max-width: 1279px) {
    .navmenu {
        font-size: 16px;
    }

    .connect {
        font-size: 16px;
        padding: 15px 26px;
        margin-left: 10px;
    }
    

}
@media (max-width: 890px) {
  .rightNav {
    gap: 10px;
  }
  .icons{
    gap: 5px;
  }
}
@media (max-width: 768px) {
    .icons{
        margin-top: 0px;
       
    }

    /* .profileIcon {
        display: none;
    } */

    .mobile_profileIcon {
        display: none;
    }
   
    .dropdown {
        margin-left: 70px;
    }

    .rightNav {
      display: none;
        
    }
    .mobilenav{
       
        flex-direction: column;
        position: absolute;
        background: #C86550;
        top: 0px;
        height: 464px;
        padding: 0px;
        display: block;
        width: 292px;
        z-index: 5;
    }
    .iconclose{
        padding-left: 20px;
        padding-top: 20px;
    }
    .navplan{
        font-family: Poppins;
        font-size: 20px;
        font-weight: 500;
        color: #ffffff;
        line-height: 30px;
        padding-left: 20px;
    }
 

 .profilemenuItem {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  color: #ffffff; /* Text color */
  transition: background-color 0.3s ease;
}

.profilemenuItem:hover {
  background-color: #f0f0f0; /* Hover effect */
}

.profileactive {
  background-color: #FCFCFC; /* Active menu item background color */
  color: #C86550; /* Active menu item text color */
  font-weight: bold;

}

.profileicon {


  font-size: 18px;
  color: #666; /* Icon color */
  transition: color 0.3s ease;
  width: 30px;
   
}

.profileactive .profileicon {
  color: #fff; /* Change icon color when active */
}

    .navmenu a {
        margin-left: 0px;
    }

    .opened {
        left: -30px !important;
    }

    .mainHeader {
        width: 100%;
       
        justify-content: space-between;
  
       
    }

    .navmenu {
        font-size: 17px;
        width: 233px;
    }

    .connect {
        margin-right: 50px;
        padding: 15px 23px;
    }

    .mainHeader svg {
      
    }
    .menuIcon svg{
        display: block;
        /* position: absolute; */
        /* left: 0px; */
        width: 78px;
    }

    .mainHeader img {
        margin-left: 0%;
        width: 70px;
      
     
    }
/* 
    .list {
        width: 200px;
        display: flex;
        flex-direction: column;
    } */
    .container{
        height: 80px;
    }
}





.searchContainer {
    position: relative;

    display: grid;

   
  }
  
  .searchIcon {
    /* display: inline-block; */
  }
  
  .searchInput {
    /* width: 300px; */
    padding: 8px;
    
width: 557px;
    border: 1px solid #ccc;
    border-radius: 4px;

  
  }
  
  .suggestions {
    /* position: absolute; */
    top: 159px; 
    width: 557px;
    /* max-height: 100%; */
    /* overflow-y: auto; */
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    /* z-index: 100; */
  }
  
  .suggestionItem {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .suggestionItem:hover {
    background-color: #f0f0f0;
  }
  
  .suggestionImage {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  
  .category {
    color: #888;
    font-size: 0.9em;
    margin-left: 5px;
  }
  .search_contant{
 
    /* display: flex; */
    width: 80%;
   
    justify-content: space-between;
    align-items: flex-end;   
    display: flex;
    flex-direction: column;
  }
  .smilerproduct{
    position: absolute;
    margin-top: 44px;
    z-index: 1;

  }
  .search_placeholder{
    width: 100%;

    justify-content: center;
    align-items: center;
    display: flex;
  }
  @media (max-width: 768px) {
    .searchInput {
        width: 307px;
    }
    /* .search_contant{
        align-items: flex-start;
    } */
    .suggestions {
        width: 307px;
    }
  }
  @media (max-width: 380px) {
    .searchInput {
        width: 257px;
    }
   
    .suggestions {
        width: 257px;
    }
  }
  @media (max-width: 400px) {
    .menu {
        width: 258px;
    }
  }


/* from headerTop */



.section{
  height: 90%;
  width: 97%;
  
  justify-content: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  }
  .text{
      font-family: Rubik;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 3%;
      color: #C86550;
      
  
  }
  /* .dropdownContainer {
      position: absolute;
      width: auto;
      right:0;
      top:0;
      z-index: 10;
   
    } */
    
    .customSelect {
      position: absolute;
      cursor: pointer;
      right:3vh;
      top:0;
      padding-top: 5px;
      border-radius: 4px;
      width: auto;
      z-index: 15;
    }
    
    .selectedOption {
      
      display: flex;
      align-items: center;
      justify-content: end;
      font-family: Poppins;
      font-weight: 300;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 3%;
      color: #C86550;
      white-space: nowrap;
      gap:5px;
    }
    
    .optionsList {
      display: none;
      /* position: absolute; */
      top: 100%;
      /* left: -20%; */
      width: inherit;
      border: 1px solid #ccc;
      border-radius: 4px;
      background: white;
      list-style: none;
      padding: 5px;
      margin: 0;
      z-index: 10;
      white-space: nowrap;
    }
    
    .customSelect:hover .optionsList,
    .customSelect:focus .optionsList {
      display: block;
    }
    
    .option {
      padding: 5px 10px;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 12px;
      color: #C86550;
      text-transform: capitalize;
    }
    
    .option:hover {
      background: #f0f0f0;
    }
/*     
    .selected {
      background-color: #C86550;
    } */
    .line {
      width: 0.5px; /* Thickness of the line */
      height: 22px; /* Adjust height as needed */
      background-color: #dfdede; /* Color of the line */
      margin: 0 auto; /* Center the line horizontally within its container */
      display: block;
    }
    .right{
      display: flex;
      align-items: center;
      gap: 30px;
    }
    @media (max-width: 470px) {
      .text{
          font-family: Rubik;
          font-weight: 400;
          font-size: 11px;
          line-height: 11px;
          letter-spacing: 3%;
          color: #C86550;
          
      
      }
      /* .right{
          
          gap: 10px;
        } */
        /* .dropdownContainer {
          position: relative;
          width: 60%;
         
        } */
        
    }
    @media (max-width: 351px) {
      .text{
          font-family: Rubik;
          font-weight: 400;
          font-size: 9px;
          line-height: 11px;
          letter-spacing: 3%;
          color: #C86550;
          
      
      }
      /* .right{
          
          gap: 8px;
        } */
        /* .dropdownContainer {
          position: relative;
          width: 60%;
         
        } */
    }
  