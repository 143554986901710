.breadcrumbs {
    display: flex;
    align-items: center;
    height: 40px;
 padding: 20px;
  }
  
  .homeLink {
    text-decoration: none;
    color:#7E7E7E;
   
margin-left: 25px;

  }
  
  .breadcrumbItem {
    display: flex;
    align-items: center;
    margin-left: 15px;
    font-family: Public Sans;
    gap: 10px;
    margin-top: 3px;
  }
  
  .breadcrumbLink {
    text-decoration: none;
 /* color: #C86550; */
    margin-left: 10px;
  
    font-family: Public Sans;
  }
  .arrow{
    display: none;
  }

  
  @media  (max-width: 769px){
    .arrow{
      display: block;
    }
    .homeLink {
      font-family: Public Sans;
font-size: 8px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0.005em;


    }
    .breadcrumbItem {
      font-family: Public Sans;
font-size: 8px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0.005em;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

    }
    .homeLink {
      margin-left: 10px;
    }
  }
 
  