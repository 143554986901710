.chekoutContainer {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    margin: 20px 100px; 
}

.loader {
width: 100%;
height: 100vh;
display: flex;
align-items: center;
justify-content: center;
z-index: 1000;

}



.errorText{
    font-size: 12px;
    margin-top: 1;
    color: red;
    position: relative;
   
  }

.chekoutForm {
    width: 55%;
 
}

.chekoutOrder {
    width: 36%;
}

.profileform {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    /*   margin: 4% 10%; */
}

.profilerow {
    display: flex;
    gap: 15px;
}

.profileformGroup {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.profileformGroup label {
    font-size: 14px;
    color: #555;
    margin-bottom: 8px;
}

.profileformGroup input,
.profileformGroup select {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
}

.checkboxGroup {
    display: flex;
    align-items: center;
    gap: 8px;
}

.checkboxGroup input {
    width: 18px;
    height: 18px;
}

.buttonGroup {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.cancelButton {
    background-color: transparent;
    color: #C86550;
    border: 1px solid #C86550;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.saveButton {
    background-color: #C86550;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
}

.cancelButton:hover {
    background-color: #C86550;
    color: white;
}

.saveButton:hover {
    background-color: #C86550;
}

.orderSummary {
    border: 1px solid #ddd;
    padding: 20px;
}

.profileform h2 {
    margin: 10px 0px 0px;
}

.paymentOption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.paymentLabel {
    font-size: 16px;
    margin-bottom: 0px !important;
    margin-left: 4px;
}

.orderdeets {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hline {
    border: none;
    border-top: 1px solid #D1D1D8;
    width: 100%;
    margin: 20px auto;
    opacity: 0.7;
}

.pay_methodHead{
  margin-block: 5%;
  padding: 5%;
  background-color: #FAF3E069;
}

.pay_methodtitle{
padding-bottom: 5%;
}

.chekoutCustminfo span {
    color: #D85D5D;
    font-weight: 700;
    margin-left: 8px;
}

.addressContainer {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
  
  }
  
  h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .addressCard {
    border: 2px solid #C8655040;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 15px;
    background-color: #FAF3E069;
    transition: background-color 0.3s ease;
    position: relative;
    
  }
  
  .selected {
    background-color: #FAF3E069;
    border: 2px solid #C8655040;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 15px;
    transition: background-color 0.3s ease;
    position: relative;
  }
  
  .addressHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
   
  }

  .addressSubHeader {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  
  .addressName {
    font-size: 1rem;
  }
  
  .editIcon {
    cursor: pointer;
    font-size: 1rem;
  }
  
  .addressDetails {
    font-size: 0.8rem;
    margin: 5px 0;
  }
  
  .addressPhone {
    font-size: 0.8rem;
    color: #333;
  }
  
  .addNewAddress {
    text-align: center;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 50px;
    border-top: 2px dotted rgb(243, 237, 237);
    background: linear-gradient(#fff,#fff) padding-box,
     linear-gradient(92.35deg, #080808 1.28%, hsl(0, 0%, 100%) 98.95%) border-box; 
}
.addNewAddress {
  text-align: center;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 20px;
  margin-top: 50px;
  border-bottom: 1px solid #D1D1D8;

  border-top: 2px dashed rgb(243, 237, 243);
  background: linear-gradient(#ffffff, #ffffff) padding-box,
      /* Three-color gradient with specified color stops */
      linear-gradient(92.35deg, #ffffff 0%, #000000 50%, #ffffff 100%) border-box;
}
 
  
  .addAddressButton {
    border: none;
   
    background-color: transparent;
    color: #C86550;
    font-size: 1.1rem;
    cursor: pointer;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: -20px;
    
    gap: 10px;
  }
  .bottomline{
    margin-bottom: 30px;
  }
  
  .addAddressButton:hover {
    color: #C86550;
   
  }
  
  @media screen and (max-width: 768px) {
    .addressContainer {
      padding: 10px;
    }
    
    .addressCard {
      padding: 10px;
    }
  
    .addressName, .addressDetails, .addressPhone {
      font-size: 0.9rem;
    }
  
    .addAddressButton {
      font-size: 1rem;
    }
  }
  



@media (max-width: 860px) {
    .profileheader {
        padding: 20px 20px;
    }

    .profileform {
        margin: 4% 0%;
    }

    .profilerow {
        flex-direction: column;
    }

    .profilebodyContainer {
        border: none;
    }

    .profilebodyDetails {
        width: 100%;
        margin-right: 0px;
    }

    .savechanges {
        width: 100%;
    }

    .profileform h2 {
        font-size: 20px;
    }

    .chekoutContainer {
        margin: 20px 20px;
        flex-direction: column;
    }
    .chekoutForm{
        width: 100%;
    }
    .chekoutOrder{
        width: 100%;
    }
}


@media (min-width: 860px) and (max-width: 1023px) {
    
    .chekoutContainer {
        margin: 20px 60px;
    }
    .chekoutForm{
        width: 60%;
    }
    .chekoutOrder{
        width: 40%;
    }
}