.container{
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  
background-color: rgb(248, 248, 248);
}
.section{
    width: 95%;
    height: 80%;
   
    justify-content: center;
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: 3rem;
   margin-bottom: 5rem;
   
}
.titles{
   width: 100%;
  height: 20%;
   
    margin-bottom: 2rem;  
}
.heading{
    font-family: Rubik;
    font-size: 31px;
    font-weight: 500;
    margin-top: -0.2rem;
    text-align: center;
      }
.text{
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
   margin-top: -1rem;
    text-align: center;

}
.boxs{
    width: 100%;
    height: 100%;
  
    display: flex;
    justify-content: center;
  gap: 59px;
    align-items: center;
   
}

/* .mainImg_1{
width: 640px;
height: 408.12px; */
.mainImg_1 {
    width: 100%; /* Makes the image fill the parent container's width */
    height: auto; /* Adjusts the height to maintain the aspect ratio */
    object-fit: contain; /* Ensures the image doesn't stretch and covers the container */
    border-radius: 8px; /* Optional: adds rounded corners */
}

/* } */
.box1{

 height: 100%;
  
    justify-content: center;
    display: flex;
}
.box2{
  
    height: 100%;
     
       justify-content: center;
       display: flex;
}
@media  (max-width: 1405px){
    .mainImg_1{
        width: 540px;
        height: 408.12px;
        }
}
@media  (max-width: 1193px){
    .mainImg_1{
        width: 440px;
        height: 308.12px;
        }
}
@media  (max-width: 982px){
    .boxs{
        width: 100%;
        height: 100%;
       
        display: flex;
        justify-content: center;
       justify-content: space-between;
        align-items: center;
        flex-direction: column;
        gap: 2rem;
       
    }
}
@media  (max-width: 465px){
    .mainImg_1{
        width: 300px;
        height: 208.12px;
        }
        .heading{
            font-family: Rubik;
            font-size: 21px;
            font-weight: 500;
            margin-top: -0.1rem;
            text-align: center;
              }
        .text{
            font-family: Rubik;
            font-size: 12px;
            font-weight: 400;
           margin-top: -1rem;
            text-align: center;
        
        }
        .section{
            margin-top: 0rem;
            margin-bottom: 3rem;
        }
}