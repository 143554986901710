.Container{
    display: flex;
  justify-content: center;

  flex-wrap: wrap;
  
  
  }
  .mobilecontainer{
    display: none;
  }
  .cartContainer {
    display: flex;
  justify-content: space-between;
   gap: 10px;
   flex-wrap: wrap;
    width: 90%;
   
  }
  .checkoutButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;   
  }
  
  .cartItems {
    width: 60%;
   
  }
  
  .variation{
    color: #555555;
    font-size: 12px;
  
  }
  
  .cartTable {
    width: 100%;
    border-collapse: collapse;
  
  }
  .heading{
    font-family: Poppins;
    font-weight: 500;
    font-size: 16px;
  }

    .head1{
   font-family: Poppins;
    font-weight: 400;
    font-size: 16px; 
    color: #232323;
   
   
  }

  .head{
   font-family: Poppins;
    font-weight: 400;
    font-size: 16px; 
    color: #232323;
    white-space: nowrap;
   
  }
  .productDetailes{
    border-top: #9F9F9F 2px solid;
  }
  
  .cartTable th,
  .cartTable td {
    padding: 15px;
    text-align: left;
  
  }
  .opreter{
    border: none;
    background-color: transparent;
    color: #9F9F9F;
  }
  .num{
  color: #9F9F9F;
  }
  
  .productImage{
    width: 100px;
    height: 91px;
    cursor: pointer;
  }
  
  .quantityControls {
    display: flex;
    align-items: center;
   
  }
  .Controls{
     display: flex;
    align-items: center;
    border: 1px solid #9F9F9F;
  }
  
  .quantityControls button {
    padding: 5px;
    margin: 0 5px;
  }
  
  .removeButton {
    background-color: transparent;
    border: none;
    color: #ff0000;
    cursor: pointer;
    
  }
  
  .addMoreItems {
    margin-top: 20px;
    cursor: pointer;
  }
  
  .more {
    color: #e26d5c;
    
    text-decoration-line: underline;
    /* text-decoration-color: #e26d5c ; */
    font-weight: bold;
    font-size: 20px;
    font-family: Poppins;
    font-weight: 500;
    line-height: 30px;
  }
  
  .orderSummary {
    width: 25%;
    border: 1px solid #ddd;
    padding: 40px;
    margin-top: 2rem;

  
   
  }
  
  
  .Order {
  
   
    font-size: 24px;
    font-family: Poppins;
    font-weight: 600;
    line-height: 32px;
    color: #17183B;
    margin-top: -5px;
  }
  
  .summaryItem {
    display: flex;
    justify-content: space-between;
  
  }
  .priceSummary{
    font-family:Poppins;
    font-size: 16px;
    line-height: 25px;
    color: #17183B;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .FreeSummary{
    font-family:Poppins;
    font-size: 16px;
    line-height: 25px;
    color: #3AA39F;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .bold{
   font-family:Poppins;
    font-size: 16px;
    line-height: 25px;
    color: #17183B;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 20px;
   
  }
  
  .summaryTotal {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 1.2em;
  }
  
  .checkoutButton {
    width: 100%;
    padding: 20px;
    background-color: #e26d5c;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-family: Poppins;
font-size: 16px;
font-weight: 600;
line-height: 20px;
letter-spacing: 0.10000000149011612px;


  }
  
  .checkoutButton:hover {
    background-color: #d65f54;
  }
  /* @media (max-width: 1262px) {
  
  .cartContainer {
    display: flex;
  justify-content: center;
  gap: 30px;
  justify-content: space-between;
  
    width: 95%;
   
  }
  .orderSummary {
    width: 30%;
    border: 1px solid #ddd;
    padding: 40px;
    
   
  }
  } */
  @media (max-width: 378px) {
    .priceSummary{
        font-family:Poppins;
        font-size: 13px;
        line-height: 25px;
        color: #17183B;
        font-weight: 400;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .FreeSummary{
        font-family:Poppins;
        font-size: 13px;
        line-height: 25px;
        color: #3AA39F;
        font-weight: 400;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .bold{
       font-family:Poppins;
        font-size: 13px;
        line-height: 25px;
        color: #17183B;
        font-weight: 600;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: right;
       
      }
  }
  @media (max-width: 1312px) {
    .cartTable td {
        padding: 10px;
    
     
    }
  
  }
  @media (max-width: 1092px) {
  .cartContainer {
    flex-wrap: wrap;
    align-items: center;
      display: flex;
  justify-content: center;
  }
  .cartItems {
    width: 90%;
   
  }
  .orderSummary {
     width: 55%;
  }
  }
  @media (max-width: 850px) {
  .orderSummary {
     width: 65%;
  }
  }
  @media (max-width:769px) {
    .orderSummary {
        width: 73%;
        border-radius: 5px;
    }
    .cartContainer {
  display: none;

    }
   
   
      .mobilecontainer{
        display: block;
        width: 100%;
       
      
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .mobileheading{
        color: #e26d5c;
      text-decoration: underline ;
      text-decoration-color: #e26d5c ;
      font-weight: bold;
      font-size: 20px;
      font-family: Poppins;
      font-weight: 500;
      line-height: 30px;
    text-align: right;
    text-decoration: underline;
    width: 90%;
    color: #d65f54;
    
    
    
      }
      .rast{
        justify-content: flex-end;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        text-align: right;
      }
      .mobilecontant1{
        width: 80%;
       
        border: 1px solid #E6E6E6;
       padding: 20px 20px 0px 20px;
        
        border-radius: 20px;
    
      }
      .mobilecontant{
        border-bottom: 1px solid #e6e6e6;
    
        margin-top: 1rem;
      }
    
    
      .mobilebox{
        display: flex;
        justify-content: space-between;
     
       height: 50px;
     
      }
      .mobilebox1{
        display: flex;
        justify-content: space-between;
    
    
      }
  
  }
  