.container44{
    padding: 20px;
    max-width: 400px;
    margin: auto;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  .ratingSummary {
    display: flex;
    padding: 16px;
    border-bottom: 1px solid #eee;
    justify-content: space-between;
  }
  
  .averageRating {
    text-align: center;
  }
  
  .ratenum {
    font-size: 48px;
    margin: 0;
    color: #333;
    
  }
  
  .stars {
    color: #ffb400;
    font-size: 18px;
    margin-top: 4px;
  }
  
  .averageRating p {
    font-size: 14px;
    color: #757575;
    margin: 4px 0 0;
  }
  
  .ratingBreakdown {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .ratingRow {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .star_1{
    font-size: 14px;
    color: #333;
  }
  
  .ratingBar {
    width: 100px;
    height: 6px;
    background-color: #eee;
    border-radius: 3px;
    overflow: hidden;
  }
  
  .filledBar {
    height: 100%;
    background-color: #e57373;
  }
  
  .reviews {
    margin-top: 16px;
  }
  
  .review {
    padding: 16px 0;
    border-bottom: 1px solid #eee;
  }
  
  .reviewHeader {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
  }
  
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .reviewInfo {
    flex-grow: 1;
  }
  
  .reviewInfo h3 {
    margin: 0;
    font-size: 16px;
    color: #333;
  }
  
  .reviewInfo p {
    margin: 0;
    font-size: 14px;
    color: #757575;
  }
  
  .time {
    margin-left: 8px;
  }
  
  .moreOptions {
    font-size: 18px;
    color: #757575;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .reviewText {
    margin-top: 8px;
    font-size: 14px;
    color: #333;
  }
  