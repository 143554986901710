.myprofile{
    display: flex;
    gap :40px;
    padding: 40px 60px;
}
.prodetailsContainer {
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 8px;
    width: 100%;
}

.profilebodyContent {
    display: flex;
    align-items: center;
    padding:20px;
}
.profileheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    border-bottom: 1px solid #eee;
}

.profileheader h2 {
    font-size: 24px;
    color: #333;
    /* margin: 0px; */
}
.editButton {
    background-color: #d87156;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;
}

.prodetails{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #eee;
    width: 70%;
}

.prorow{
    display: flex;
    gap: 20px;
}

.procustomer{
    width: 30%;
}
.procname{
    width: 60%;
}


.orderbuttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 0px 15px 15px 15px;
  }
  
  .trackOrderBtn,
  .invoiceBtn {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .trackOrderBtn {
    background-color: #fff;
    color: #C86550;
    border: 1px solid #C86550;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .trackOrderBtn:hover {
    background-color: #C86550;
    color: #fff;
    transform: scale(1.1);
  }
  
 /*  .invoiceBtn {
    background-color: #C86550;
    color: white;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .invoiceBtn:hover {
    background-color: #fff;
    color: #C86550;
    border: 1px solid #C86550;
    transform: scale(1.1);
  } */
  
.card {
    width: 300px;
    /* padding: 15px; */
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    font-weight: 500;
    border-bottom: 1px solid #eee;
    padding: 15px;
  }
  
  .default {
    color: #C86550; /* Red color for 'DEFAULT' */
    font-size: 12px;
    font-weight: bold;
  }
  
  .content {
    margin-bottom: 15px;
    padding: 0px 15px;
  }
  
  .name {
    font-size: 16px;
    font-weight: bold;
  }
  
  .address {
    font-size: 14px;
    color: #555;
    margin: 5px 0;
  }
  
  .phone {
    font-size: 14px;
    color: #333;
  }
  

  
  



@media  (max-width: 768px){
  .profileheader{
      padding: 20px 20px;
  }
    .editButton{
        background-color: transparent;
        color: #d87156;
        border: none;
        padding: 0px 0px;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        text-decoration-line: underline;
    }
    .profilebodyContent {
        justify-content: center;
    }
    .prodetailsContainer{
        border: none;
    }
    .promenu{
        display: none;
    }
    .myprofile{
        padding: 0px 0px;
    }
    .profileheader h2{
        font-size: 18px;
    }
    .prodetails{
        width: 100%;
        padding: 0px;
        border: none;
    }
    .procustomer{
        width: 40%;
    }
    .procname{
        width: 60%;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .myprofile{
        padding: 0px 60px;
    }
}
@media (min-width: 1024px) and (max-width: 1440px) {
    .myprofile{
        padding: 40px 60px;
    }
}