@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');

.App {
  text-align: center;
}

html, body {
  font-family: 'Rubik', sans-serif;
  margin: 0;
  padding: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.slidercontant .slick-dots li button:before {
  /* background-color: #b2080866; */
  color: #3a3939;
  font-size: 15px;
  margin-top: 30px;
}

.slidercontant .slick-dots li.slick-active button:before {
  color: #C86550 !important;
  opacity: 1;
}

/* margin between slides */
.slidercontant .slick-slide > div {
  margin: 0 10px;
}
.slidercontant .slick-list {
  margin: 0 -10px;
}

.testimonial .slick-dots li button:before {
  color: transparent;
  background-color: #57545466;
  width: 19px;
  height: 3px;
  
 
}

.testimonial .slick-dots li.slick-active button:before {
  color: transparent !important;
  opacity: 1;

}

.testimonial .slick-dots {

 height:-90px;
 justify-content: center;
 align-items: center;
 display: flex;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (max-width: 1025px) {
  .slidercontant .slick-dots li button:before {
    margin-top: 10px;
  }
}
