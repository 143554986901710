.container{
  height: 100%;
  width: 100%;
 display: flex;
 background-color: rgb(251, 251, 251);

 
}

.filterContainer {
  display: flex;
  align-items: center;
  margin-block: 10px;
}

.appliedFilters {
  padding: 0px 24px 0px 24px;
  display: flex;
  align-items: center;
  overflow-y: auto; 
  white-space: nowrap;
  scrollbar-width: none; 
  -ms-overflow-style: none; 
}

.appliedFilters::-webkit-scrollbar {
  display: none;
}

.filterChip {
  /* background-color: #e0e0e0; */
  border-radius: 4px;
  padding: 4px 8px;
  margin-right: 6px;
  display: inline-block;
  font-size: 0.9em;
  color: #1D83F8;
  font-family: Rubik;
}

.clearAll {
color: #C86550;
cursor: pointer;
white-space: nowrap;
padding-inline: 10px;
}


.filterStyle {
  width: fit-content;
  height: 40px;
  padding: 0px 10px 0px 10px;
  gap: 10px;
  border: 1px solid #C86550;
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 40px;
}

.filterText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  /* line-height: 40px; */
  letter-spacing: -0.008em;
  text-align: left;
  color: #C86550;
}

.countStyle {
  width: 16px;
  height: 20px;
  padding: 4px 6px 4px 6px;
  background: #C86550;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  /* text-align: center; */
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

}


.colorCircle {
margin-top: 5px;
border: 1.5px solid #EEEEEE;
margin: -2px;
width: 15px;
height: 15px;
border-radius: 50%;
display: inline-block;
margin-right: 8px;
}



.section1 {
 
background-color: rgb(255, 255, 255);
  width: 20%;
  height: 100%;
justify-content: center;
display: flex;
flex-direction: column;
margin-left: 20px;
padding: 20px;

 
}

.search {
  margin-bottom: 10px;
  margin-top: -1.5rem;
}

.searchInput {
  width: 80%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #F5F5F5;
  margin-top: 15px;
  background-color: #F5F5F5;
}

.filterSection {
  margin-bottom: 20px;
  width: 100%;
  justify-content: center;
display: flex;
flex-direction: column;

}
.filterSection_1{
  width: 100%;
  justify-content: space-between;
  display: flex;
align-items: center;
cursor: pointer;

}


.searchTitle {
  font-size: 20px;
  font-family: Rubik;
  font-weight: 500;
  margin-bottom: 10px;
  color: #463F3F;
  line-height: 28px;
  
}
.catagoryTitle{
  font-size: 20px;
  font-family: Rubik;
  font-weight: 500;
  margin-bottom: 10px;
  color: #463F3F;
  line-height: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  
}
.priceTitle{
  font-size: 20px;
  font-family: Rubik;
  font-weight: 500;
  margin-bottom: 10px;
  color: #463F3F;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  line-height: 28px;
}
.colorTitle{
  font-size: 20px;
  font-family: Rubik;
  font-weight: 500;
  margin-bottom: 10px;
  color: #463F3F;
  line-height: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.sizeTitle{
  font-size: 20px;
  font-family: Rubik;
  font-weight: 500;
  margin-bottom: 10px;
  color: #463F3F;
  line-height: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

}
.priceTitle2{
  font-size: 20px;
  font-family: Rubik;
  font-weight: 500;
  margin-bottom: 10px;
  color: #463F3F;
  line-height: 28px;
}

.arrowUp {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 8px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #000; /* Black color for the arrow */
}

.arrowDown {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 8px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #000; /* Black color for the arrow */
}

.icon {
  /* Optional: Customize the size and position of the icon */
  vertical-align: middle;
}

.priceFilterContent {
  margin-top: 10px;
}

.priceRange {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.line{
  width: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.priceRange input {

  height: 30px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 15px;
  margin-bottom: 5px;
  color: #000;
}

.saveButton {
  display: block;
  width: 100%;
  padding: 8px;
  border: 2px solid #D0634F;
  color: #D0634F;
  text-align: center;
 margin-bottom: 10px;
background-color: #fff;
  cursor: pointer;

}

.priceList {
  list-style: none;
  padding: 5px;
  margin-top: 10px;
 
}


.filterList{
  list-style: none;
  padding: 0;
  margin: 0;
}
.priceList2{
  list-style: none;
  padding: 0;
  margin: 0;
}

.filterList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.categoryItem {
  padding: 1px 10px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Background color for selected category */
.categoryItem.active {
  padding: 1px 10px;
  background-color: #FAF3E0; /* Example background color */
  color: #333; /* Text color to contrast with the background */
}





.sizeList {
  list-style: none;
  padding: 0;
  margin: 0;



}
.colorList{
  list-style: none;
  padding: 0;

  width: 100%;
  justify-content: space-between;
 
}
.filterList li{
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-family: Plus Jakarta Sans;
  font-size: 15px;
  font-weight: 500;
  line-height: 27px;
  color: #7E7E7E;


}
.priceList li{
margin-top: 5px;
}
.priceList2 li{
  margin-top: 5px;
  color: #7E7E7E;
}



.sizeList li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}


.priceRange {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.priceRange input {
  width: 45%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}



.colorItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  color: #7E7E7E;


 
 
  
}
.frist{
  display: flex;
align-items: center;


width: 150px;

}.text55{
  color: #000;
}
.text{
margin-top: 22px;
}
.figmachange{
  display: flex;
  justify-content: space-between;
align-items: center;

 
}

.num{
 
 width: 50px;
 background-color: rgb(64, 36, 33);
 }



.colorBlack, .colorBrown, .colorRed, .colorWhite, .colorYellow {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
      margin-right: 20px;
      margin-top: 5px;
}

.colorBlack { 
  background-color: #000;

}
.colorBrown { background-color: #a52a2a; }
.colorRed { background-color: #f00; }
.colorWhite { background-color: #fff; border: 1px solid #ccc; }
.colorYellow { background-color: #ff0; }
  

/* section2 is right container */


.section2{
  width: 80%;
  height: 100%;

  justify-content: center;
  display: flex;
 
 flex-direction: column;
 align-items: center;
 margin-top: 20px;


}
.box2{

height: 10vh;
width: 90%;
margin-bottom: 20px;
justify-content: center;
display: flex;
align-items: center;
justify-content: space-between;

}
.menubars{
gap: 15px;
display: flex;
justify-content: center;
align-items: center;
}
.show12{
font-family: Rubik;
font-size: 15px;
font-weight: 500;
color: #463F3F;
}
.dropdownContainer {
position: relative;
display: flex;
align-items: center;
}

.selectBox {
padding: 15px 10px 15px 10px;
font-size: 14px;
border: 1px solid #c4bdbd;
border-radius: 20px;
appearance: none; /* Removes default dropdown arrow */
color:#463F3F ;
font-family: Rubik;
outline: none;
font-size: 15px;
font-weight: 500;

}

.icon {
position: absolute;
right: 10px;
pointer-events: none; /* So that the icon does not interfere with the dropdown */
}
.dropdown {
  position: relative;
  display: inline-block;

}

.dropdownbutton {
  background-color: #ffffff;
  border: 1px solid #F5F5F5;
  /* padding: 15px 15px; */
  cursor: pointer;
  border-radius: 15px;
  font-weight: bold;

  text-align: left;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 15px ;
}
.gaparrow{
  gap: 20px;
  
  display: flex;
  align-items: center;
 
}

.dropdown_content {
  position: absolute;
  background-color: #fff;
/* width: 975px; */
  border-radius: 5px;
  /* padding: 10px; */
  z-index: 1;
  width: 275px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 12px 22px rgba(0, 0, 0, 0.2); /* Bigger shadow */
  margin-left: -120px;
  
}

.dropdown_header {
  margin: 0;
  padding:  10px;
  font-weight: bold;
  font-size: 14px;
  color: #888;
  font-family: Rubik;
font-size: 14px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0.5px;


}

.dropdown_option {
  display: flex;

  align-items: center;
  padding: 15px 10px;
  font-size: 14px;
  cursor: pointer;
  color: #333;
  border-bottom: 2px solid #cbcbcb;
}

.dropdown_option:last-child {
  border-bottom: none;
}

.dropdown_option input[type="radio"] {
  margin-right: 10px;
}

.dropdown_option:hover {
  background-color: #f0f0f0;
}
.bag{
width: 90%;

height: 100%;
display: grid;


grid-template-columns: repeat(3, 1fr); 
gap: 40px; 

}

.box1{
  height:100%;
  width: 283px;

  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #d8d5d5;
  border-radius: 3px;
  /* background-color: sandybrown; */
  gap: 5px;
}
.maincontainer{
  height: 273px;
  width: 260px;
  position: relative;
  margin-top: 10px;
}
.mainImg_1{
  height: 100%;
  width: 100%;
}

.sale{
position: absolute;
color:rgb(247, 237, 237);
background-color: #C86550;
height: 25px;
width: 60px;
text-align: center;
font-family: Poppins;
font-size: 13px;
font-weight: 400;
line-height: 25px;
letter-spacing: 3%;

}
.stars{
margin-top: -5px;
/* background-color: salmon; */
margin-bottom: 5px;
}
.price{
display: flex;
gap: 5px;
margin-top: -10px;

}
.beforeprice{
text-decoration: line-through;
}
.name {
font-family: Rubik;
letter-spacing: 0.005em;
line-height: 17.2px ;
font-weight: 400;
font-size: 14px;
margin-top: 10px;
text-decoration: none;
list-style: none;
color: #232323;
}

.discoutprice{
font-family: Rubik;
font-size: 14px;
font-weight: 500;
line-height: 3px;
text-decoration: none;
color: #232323;
}
.beforeprice{
font-family: Rubik;
font-size: 12px;
font-weight: 500;
line-height: 8px;
color: #A69E9E;

text-decoration: line-through;
}
.content{
  display: flex;
  justify-content: center;
  justify-content: space-between;
  /* background-color: seagreen; */
  width: 270px;
  
  
  
  }
.left{

  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.right{
 justify-content: space-between;
 padding-bottom: 18px;

 align-items: flex-end;
 display: flex;
 flex-direction: column;
}
.Like{
margin-top: 10px;

}
.AddCart{
margin-top: 25px;
font-family: Rubik;
font-size: 14px;
font-weight: 400;
line-height: 16.59px;
color: #374151;
text-decoration: none;

width: 110px;

}
.pagination {
display: flex;
justify-content: center;
align-items: center;
gap: 10px; /* Space between buttons and text */
margin-top: 20px;
}

.paginationButton1 {

border: none;
color: white;
padding: 10px 0px;
cursor: pointer;
font-size: 16px;
border-radius: 5px;
transition: background-color 0.3s ease;
background-color: transparent;
margin-top: 5px;
}

.paginationButton1:disabled {

cursor: not-allowed;
}



.pageInfo {
font-size: 18px;
font-weight: bold;
color: #333;
}
.paginationleft{
display: inline-block;
width: 20px;
height: 20px;
border-radius: 50%;
    margin-right: 20px;
    margin-top: 5px;

}
.paginationButton {
padding: 8px;
border: none;
background-color: white;
color: #858585;
cursor: pointer;
margin: 0 0px;
border-radius: 40px;
font-size: 14px;
width: 35px;
height: 35px;
border: 1px solid #d6cccc;
}
.productcount{
  display: none;
}

/* Active pagination button style */
.paginationButton.active {
background-color: #C86550; /* Change this color to your preferred active color */
color: white;
}
.mobilefilter {

position: fixed;
bottom: 0;
left: 0;
width: 100%;
z-index: 1000;
background-color: #f8f8f8;
height: 50px;
justify-content: center;
display: flex;
align-items: center;
border-top-left-radius: 25px; /* Rounded corners */
border-top-right-radius: 25px; /* Rounded corners */
gap: 50px;
box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1); /* Background shadow */
display: none;
}
.Sort{
padding: 10px 32px;
color: #000;
background-color:white;
font-family: Rubik;
font-size: 14px;
font-weight: 600;
/* line-height: 24px; */
border: none;
width: fit-content;
transition: 0.3s ease-in;
margin-left: 0px;
border-radius: 10px;
box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
}
.Sort:hover{
background-color: rgba(255, 255, 255, 0.9);
color: #D0634F;
border: 1px solid #D0634F;
}
.Filter{
padding: 10px 32px;
color: #000;
background-color:white;
font-family: Rubik;
font-size: 14px;
font-weight: 600;
/* line-height: 24px; */
border: none;
width: fit-content;
transition: 0.3s ease-in;
margin-left: 0px;
border-radius: 10px;
box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
}
.Filter:hover{
background-color: rgba(255, 255, 255, 0.9);
color: #D0634F;
border: 1px solid #D0634F;
}
@media (max-width: 1322px) {
.bag{
  gap: 20px; 
  width: 95%;
 
}
.box2{
  width: 95%;
}
}
@media (max-width: 1222px) {
.bag{
  grid-template-columns: repeat(2, 1fr);
 width: 76%;
}

}
@media (max-width: 1002px) {
.bag{
  grid-template-columns: repeat(2, 1fr);
 width: 90%;
}
}
@media (max-width: 876px) {
.bag{
  grid-template-columns: repeat(2, 1fr);
 width: 99%;
}
}
@media (max-width: 826px) {
  .productcount{
    display: block;
    background-color: transparent;
    width: 95%;
    align-items: flex-end;
    display: flex;
    justify-content: right;
    margin-top: -68px;
    margin-bottom: 2rem;
  }
  .show124{
    font-family: Public Sans;
font-size: 14px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0.005em;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #000000;
margin-top: -9px;
  }
.section1 {
  display: none;
}
.container{
  justify-content: center;
}
.box2{
  display: none;
}
.bag{
  width: 95%; 
  grid-template-columns:none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  justify-content: center;
  gap: 20px; 
}
.section2{
  width: 100%;
}
.mobilefilter {

  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: #f8f8f8;
  height: 80px;
 

  flex-direction: row;
  border-top-left-radius: 25px; /* Rounded corners */
  border-top-right-radius: 25px; /* Rounded corners */
  gap: 50px;
  box-shadow: 0px -5px 20px rgba(0, 0, 0, 0.368); /* Background shadow */
  display: block;
  justify-content: center;
  display: flex;
  align-items: center;
}
.Sort{
  padding: 10px 32px;
  color: #000;
  background-color:white;
  font-family: Rubik;
font-size: 14px;
font-weight: 600;
/* line-height: 24px; */
  border: none;
  width: fit-content;
  transition: 0.3s ease-in;
  margin-left: 0px;
  border-radius: 10px;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
}
.Sort:hover{
  background-color: rgba(255, 255, 255, 0.9);
  color: #D0634F;
  border: 1px solid #D0634F;
}
.Filter{
  padding: 10px 32px;
  color: #000;
  background-color:white;
  font-family: Rubik;
font-size: 14px;
font-weight: 600;
/* line-height: 24px; */
  border: none;
  width: fit-content;
  transition: 0.3s ease-in;
  margin-left: 0px;
  border-radius: 10px;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
}
.Filter:hover{
  background-color: rgba(255, 255, 255, 0.9);
  color: #D0634F;
  border: 1px solid #D0634F;
}
}
@media (max-width: 745px) {
.section2{
  width: 100%;
}

.filterStyle{
  margin-left: 20px;
  height: 100%;
  gap: 5px;
  padding:0
}


}
@media (max-width: 621px) {
  .bag{
    justify-content: space-between;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }
.box1{
  width: 233px;

  /* height: 100%; */
  height: auto;
padding: 15px 10px;
  
}
.maincontainer{
  height: 223px;
  width: 220px;
  margin-top: 5px;

}
.content{
width: 220px;

}
.left{
 
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .right{
   justify-content: space-between;
   padding-bottom: 10px;

   align-items: flex-end;
   display: flex;
   flex-direction: column;
  }
  .Like{
  margin-top: 10px;
  
  }
.name {

line-height: 10.2px ;

font-size: 10px;
margin-top: 15px;

}
.stars{
margin-top: -5px;
}
.discoutprice{
font-family: Rubik;
font-size: 10px;
font-weight: 500;
line-height: 1px;
text-decoration: none;
color: #232323;
}
.beforeprice{
font-family: Rubik;
font-size: 9px;
font-weight: 500;
line-height: 4.5px;
color: #A69E9E;

}
.AddCart{
margin-top: 14px;
font-family: Rubik;
font-size: 10px;
font-weight: 400;
line-height: 16.59px;
color: #374151;
text-decoration: none;

}
.stars svg {
width: 12px; 
height: 25px;

}
}
/* 
@media (max-width: 518px) {
.box1{
  width: 159px;

 
}
.bag{
  width: 95%; 
  grid-template-columns:none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  justify-content: center;
  gap: 20px; 
}
.maincontainer{
  height: 163px;
  width: 149px;
  margin-top: 5px;
}
.stars svg {
width: 12px; 
height: 20px;

}
.content{
width: 98%;
margin-bottom: -10px;

}
.name {

line-height: 5.2px ;

font-size: 10px;
margin-top: 10px;

}
.stars{
margin-top: -5px;

}
.discoutprice{
font-family: Rubik;
font-size: 10px;
font-weight: 500;
line-height: 1px;
text-decoration: none;
color: #232323;
}
.beforeprice{
font-family: Rubik;
font-size: 9px;
font-weight: 500;
line-height: 4.5px;
color: #A69E9E;

}
.AddCart{
margin-top: 22px;
font-family: Rubik;
font-size: 8px;
font-weight: 400;
line-height: 10.59px;
color: #374151;
text-decoration: none;
}
.Like{
  margin-top: 0px;
}

} */
/* @media (max-width: 393px) {
.bag{
  gap: 15px;
}
}
@media (max-width: 404px) {
.box1{
  width: 139px;
gap: 0px;

padding: 5px 5px;

}
.bag{
  width: 95%; 
  grid-template-columns:none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  justify-content: center;
  gap: 10px; 
}
.maincontainer{
  height: 143px;
  width: 129px;
  margin-top: 5px;
}
.stars svg {
width: 5px; 
height: 10px;

}
.content{
width: 140px;
margin-bottom: -10px;
}
.name {

line-height: 5.2px ;

font-size: 8px;
margin-top: 10px;

}
.stars{
margin-top: -5px;

}
.discoutprice{
font-family: Rubik;
font-size: 8px;
font-weight: 500;
line-height: 1px;
text-decoration: none;
color: #232323;
}
.beforeprice{
font-family: Rubik;
font-size: 5px;
font-weight: 500;
line-height: 12.5px;
color: #A69E9E;

}
.AddCart{
margin-top: 15px;
font-family: Rubik;
font-size: 7px;
font-weight: 400;
line-height: 10.59px;
color: #374151;
text-decoration: none;
}
} */
/* @media (max-width: 328px) {
  .box1{
    width: 139px;
  gap: 0px;

  padding: 3px 2px;
  
  }
} */

@media (max-width: 558px) {
  .right{
    justify-content: space-between;
    padding-bottom: 15px;
 
    align-items: flex-end;
    display: flex;
    flex-direction: column;
   }
  .bag{
    justify-content: space-between;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    
  }
  .box1{
    height:100%;
    width: 283px;
  
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #d8d5d5;
    border-radius: 3px;
    /* background-color: sandybrown; */
    gap: 5px;
  }
.maincontainer{
  height: 273px;
  width: 260px;
  position: relative;
  margin-top: 10px;

}
.content{
  width: 270px;

}
.name {

  font-family: Rubik;
  line-height: 16.2px ;
  font-weight: 500;
  font-size: 16px;
  margin-top: 10px;
  text-decoration: none;
  list-style: none;
  color: #232323;

}
.stars{
margin-top: -5px;
}
.discoutprice{
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  line-height: 3px;
  text-decoration: none;
  color: #232323;
}
.beforeprice{
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  line-height: 8px;
  color: #A69E9E;
  
  text-decoration: line-through;

}
.AddCart{
  margin-top: 25px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.59px;
  color: #374151;
  text-decoration: none;
}
.stars svg {
width: 12px; 
height: 25px;

}
}
@media (max-width: 518px) {
  .AddCart{
    margin-top: 25px;
    font-family: Rubik;
    font-size: 14px;
    font-weight: 400;
    line-height: 18.59px;
    color: #374151;
    text-decoration: none;
  }
}




.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1000;
}

.modalContent {
  background: white;

  width: 100%;
 
 
}

.sortheading {
  margin-top: 0;
  font-family: Rubik;
font-size: 14px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0.5px;
color: #A69E9E;
border-bottom: 1px solid rgb(61, 61, 61);
padding: 20px;


}

.sortOption {

  border-bottom: 1px solid rgb(50, 50, 50);
  padding: 20px;
  font-family: Rubik;
font-size: 14px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0.5px;
color: #2F2F2FE5;

 
}
.sortOption:hover {
  background-color: rgba(0, 0, 0, 0.354); /* Change background color */

  cursor: pointer; /* Add pointer cursor for interactivity */
}

.closeButton {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 14px;
  background-color: #ccc;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}