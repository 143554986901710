.myprofile {
    display: flex;
    gap: 40px;
    width: 70%;
   

}

.uploadBox {
 
    font-family: Public Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.005em;
    text-align: center;
    color: #171718;
    height:30px;
  

 


}
.sizefile{
    font-family: Roboto;
font-size: 12px;
font-weight: 400;
line-height: 14.06px;
color: #999999;

}
.uploadBox p{
    font-family: Roboto;
font-size: 16px;
font-weight: 400;
line-height: 20px;
padding: 5px 17px;
background-color: #007BFF;



}

.ordetailsContainer {
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 8px;
    width: 100%;
}

.ordetailsheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    border-bottom: 1px solid #eee;
}

.ordetailsheader h2 {
    font-size: 24px;
    color: #333;
    /* margin: 0px; */
}

.ordetails_status {
    display: flex;
    gap: 10px;
    align-items: center;
}

.ordetails_status .status {
    font-size: 16px;
    color: #4D4D4D;
}
.status .changestatus{
    color: #ED0707 !important;
}
.ordetails_status .status span {
    font-size: 18px;
    color: #4D4D4D;
}

.pnamereview {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    padding: 10px 10px;
}

.editButton {
    background-color: #d87156;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;
}

.reviewbtn {
    background-color: transparent;
    color: #d87156;
    border: none;
    padding: 0px 0px;
    cursor: pointer;
    font-size: 14px;
    text-decoration-line: underline;
}

.ordetailsbody {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 12px 16px;
    gap: 20px;
    color: #666666;
}

.ordetailsaddress {
    display: flex;
    align-items: flex-start;
    padding: 12px 0px;
    gap: 20px;
    color: #666666;
}

.address_container {
    display: flex;
    /*   border-radius: 4px;
    border: 1px solid #E6E6E6; */
}

.address_box {
    border: 1px solid #E6E6E6;
}

.address_box .h3 {
    border-bottom: 1px solid #E6E6E6;
    padding: 16px;
    font-size: 16px;
    font-weight: 600;
}

.address_deets {
    padding: 12px 16px;
}

.order_deets p {
    display: flex;
    justify-content: space-between;
    padding: 0px 16px;
}

.order_summary {
    border: 1px solid #E6E6E6;
    border-radius: 4px;
    width: 36%;
}

.order_summary .order_head {
    border-bottom: 1px solid #E6E6E6;
    padding: 12px 16px;
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
}

.progress_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 50px auto;
    width: 80%;
    position: relative;
}

.progress_step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 25%;
}

.progress_step .circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #b3b3b3;
}

.progress_step p {
    margin: 10px 0 5px;
    color: #d97360;
    font-weight: 600;
}

.progress_step small {
    color: #999;
}

.progress_step.active .circle {
    background-color: #d97360;
    color: white;
}

.progress_step.active small {
    color: #7c7c7c;
}

.progress_container::before {
    content: '';
    position: absolute;
    top: 18px;
    /* Align with the middle of the circle */
    left: 12.5%;
    /* Start 12.5% into the container (middle of the first step) */
    width: 75%;
    /* Extend the line across 75% of the container */
    height: 4px;
    background-color: #f2f2f2;
    /* Line color for inactive steps */
    /* z-index: -1; */
}

.progress_step.active+.progress_step::before {
    content: '';
    position: absolute;
    top: 18px;
    /* Align with the middle of the circle */
    left: -42%;
    /* Ensure the line stretches to the previous step */
    width: 82%;
    /* Fill the space between two steps */
    height: 4px;
    background-color: #d97360;
    /* Line color for active steps */
    /* z-index: -1; */
}

.progress_step:first-child::before {
    content: none;
    /* Don't add a line before the first step */
}



.orderSummary {
    width: 96%;
    background: #FAF3E0;
    padding: 20px;
}

.ordertable {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    background-color: #fff;
}

.ordertable th,
.ordertable td {
    padding: 12px 15px;
    text-align: left;
    font-size: 14px;
}

.ordertable th {
    background-color: #FAF3E0;
}

.orderbuttons {
    display: flex;
    gap: 10px;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.productalign {
    display: flex;
    align-items: center;
    gap: 8px;
}


.order_rejection{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 24px;
    padding-top: 40px;
    width: 100%;
}
.rejection{
    width: 96%;
    border: 1px solid #E6E6E6;
    border-radius: 6px;
    padding: 10px 20px;
    font-size: 14px;
}
.rejection_inputs{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 20px 0px;
}
.rejection label{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    line-height: 24px;
}
.uploadOrderBtn,
.trackOrderBtn,
.invoiceBtn {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.uploadOrderBtn {
    width: 150px;
    background-color: #007BFF;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.uploadOrderBtn:hover {
    background-color: #007BFF;
    color: #fff;
    transform: scale(1.1);
}
.trackOrderBtn {
    background-color: #fff;
    color: #C86550;
    border: 1px solid #C86550;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.trackOrderBtn:hover {
    background-color: #C86550;
    color: #fff;
    transform: scale(1.1);
}

.invoiceBtn {
    background-color: #C86550;
    color: white;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.invoiceBtn:hover {
    background-color: #fff;
    color: #C86550;
    border: 1px solid #C86550;
    transform: scale(1.1);
}


@media (max-width: 768px) {
    .orderSummary {
        width: 100%;
        padding: 0px;
        background-color: transparent;
    }
    .pnamereview{
        padding: 0px 0px;
        background-color: #fff;
    }
    .rejection{
        width: 94%;
        padding: 10px 12px;
    }
    .order_rejection{
        flex-direction: column;
        margin-top: 0px;
        
    }
    .promenu {
        display: none;
    }

    .myprofile {
        padding: 0px 0px;
        width: 100%;
    }

    .ordetailsContainer {
        border: none;
    }

    .ordetailsaddress,
    .address_container {
        flex-direction: column;
        width: 100%;
        padding: 0px 0px;
    }

    .order_summary {
        width: 100%;
    }

    .progress_container {
        display: none;
    }

    .ordertable th,
    .ordertable td {
        padding: 12px 8px;
    }

    .ordertable {
        margin-bottom: 10px;
    }

    .ordetailsheader {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 20px 20px;
        border-bottom: none;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        gap: 20px;
    }

    .ordetailsheader h2 {
        font-size: 20px;
        margin: 0px;
    }

    .ordetails_status {
        justify-content: space-between;
        width: 100%;
        flex-direction: column;
        align-items: flex-start
    }

    .ordetails_status .status {
        font-size: 14px;
    }

    .productalign {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .editButton {
        background-color: transparent;
        color: #d87156;
        border: none;
        padding: 0px 0px;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        text-decoration-line: underline;
    }

    .orderbuttons {
        justify-content: center;
        flex-direction: column;
        margin-top: 0px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
  
    .myprofile {
        padding: 0px 30px;
        gap: 20px;
    }

    .ordetailsaddress {
        flex-direction: column;
        width: 100%
    }
}

@media (min-width: 1024px) and (max-width: 1440px) {
    .myprofile {
        padding: 40px 30px;
        gap: 20px;
    }

    .progress_container {
        width: 100%;
    }
}