


.container {
    width: 90%;
    margin: 0 auto;
    position: relative;
    background-color: white;
  }
  
  .container::before {
    content: "";
    position: absolute;
    top: 35%; /* Start the line at 25% of the container’s height */
    bottom: 15%; /* End the line at 75% of the container’s height */
    left: 50%;
    width: 1px; /* Thickness of the line */
    background-color: #ccc; /* Color of the line */
    transform: translateX(-50%); /* Center the line horizontally */
    z-index: 1; /* Ensure it stays behind the content */
 
    

  }
  
  .slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Adjust height as needed */
    margin-bottom: 50px;
   
    text-align: center;
    /* Ensure the slide content appears above the line */
  

  }
  .section{
  
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
   
   
  }
  .sec {
    display: grid; /* Enable flexbox */
   /* Align items to the left along the main axis (horizontal) */
    align-items: left; /* Align items to the top along the cross axis (vertical) */
   
    width: 70%;

   
  }
  .box{
    display: flex;
    gap: 10px;
  
   
    
  }
  
  .text {
    margin-bottom: 10px;
   
    font-size: 16px;
    font-family: Rubik;
    line-height: 28px;
    font-weight: 400;
    width: 100%;
    text-align: left;
    color:#6F6F6F;
  }

  .titles{
    justify-content: center;
    align-items: center;
    display: flex;
    color: rgb(0, 0, 0);
    margin-top: 1rem;
  }
  .tex1{
    font-size: 22px;
    font-family: Rubik;
    line-height: 24.07px;
    font-weight: 500;
    width: 100%;
    text-align: left;
    color:#232323;
  }
  @media  (max-width: 768px){
    .sec {
      width: 95%;
    }
    .container {
      width: 100%;
    }
    .container::before {
       display: none;
        
    
      }
      .text {
        text-align: center;
      }
      .box{
        justify-content: center;
        align-items: center;
        display: flex;
        width: 100%;
      }
      /* .tex1{
        text-align: center;
      } */
      
  }
