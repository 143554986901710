.myprofile{
    display: flex;
    gap :40px;
    padding: 40px 60px;
}
.prodetailsContainer {
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 8px;
    width: 100%;
}
.profilebodyContent{
    height: 80%;
}
.profilebodyContent {
    display: flex;
    justify-content: center;
    align-items: center;
    padding:20px;
}
.profileheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    border-bottom: 1px solid #eee;
}

.profileheader h2 {
    font-size: 24px;
    color: #333;
    /* margin: 0px; */
}
.editButton {
    background-color: #d87156;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;
}

.prodetails{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #eee;
    width: 70%;
}

.prorow{
    display: flex;
    gap: 20px;
}

.procustomer{
    width: 30%;
}
.procname{
    width: 60%;
}






@media  (max-width: 768px){
    .profileheader{
        padding: 20px 20px;
    }
    .profilebodyContent{
        height: 50vh;
    }
    .editButton{
        background-color: transparent;
        color: #d87156;
        border: none;
        padding: 0px 0px;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        text-decoration-line: underline;
    }
    .prodetailsContainer{
        border: none;
    }
    .promenu{
        display: none;
    }
    .myprofile{
        padding: 0px 0px;
    }
    .profileheader h2{
        font-size: 18px;
    }
    .prodetails{
        width: 100%;
        padding: 0px;
        border: none;
    }
    .procustomer{
        width: 40%;
    }
    .procname{
        width: 60%;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .myprofile{
        padding: 0px 60px;
    }
}
@media (min-width: 1024px) and (max-width: 1440px) {
    .myprofile{
        padding: 40px 60px;
    }
}