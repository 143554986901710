.myprofile{
    display: flex;
    /* gap :10px; */

}



@media  (max-width: 768px){
    .promenu{
        display: none;
    }
    .myprofile{
        padding: 0px 0px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .myprofile{
        padding: 0px 0px;
    }
}
/* @media (min-width: 1024px) and (max-width: 1440px) {
    .myprofile{
        padding: 0px 120px;
    }
} */