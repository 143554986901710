.container{
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  background-color: rgb(248, 248, 248);

}
.section{
    width: 95%;
    height: 80%;
   
    justify-content: center;
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: 3rem;
   margin-bottom: 5rem;
   
}
.titles{
   width: 100%;
  height: 20%;
   
    margin-bottom: 2rem;  
}
.heading{
    font-family: Rubik;
    font-size: 31px;
    font-weight: 500;
    margin-top: -0.2rem;
    text-align: center;
      }
.text{
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
   margin-top: -1rem;
    text-align: center;

}
.boxs{
    width: 90%;
    height: 100%;
  
    display: flex;
    justify-content: center;
  gap: 39px;
    align-items: center;
   
}

.mainImg_1{
width: 640px;
height: 408.12px;
}
.box1{

background-image: url("../../../public/assets/images/Traditional\ boys.jpg");
 height: 408px;
 width: 90%;
 /* background-size: 100%; */
 background-repeat: no-repeat;
 background-position: center;
  background-size: cover;
}
.box2{

    background-image: url("../../../public/assets/images/birthday\ party\ 2.jpg");
    height: 408px;
    width: 90%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.item1{
    width: 100%;
    height:inherit;
    background-color: #0000003d;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
 
}
.festive{
   font-family: Rubik;
   font-weight: 700;
   color: #FFFFFF;
    font-size: 34px;
    margin-left: 40px;
    line-height: 45.1px;
}
.product{
    font-family: Rubik;
    font-weight: 700;
    color: #FFFFFF;
     font-size: 16px;
     margin-left: 40px;
     line-height:25.06px;
}
.shop{
    font-family: Rubik;
    font-weight: 700;
    color: #FFFFFF;
     font-size: 20px;
     margin-left: 40px;
     line-height:30.13px;
     cursor: pointer;
     text-decoration: underline;  
     margin-top: 80px;
}
@media  (max-width: 1405px){
    .mainImg_1{
        width: 540px;
        height: 408.12px;
        }
}
@media  (max-width: 1193px){
  
    .mainImg_1{
        width: 440px;
        height: 308.12px;
        }
}
@media  (max-width: 982px){
    .section{
        margin-bottom: 1rem;
    }
    .boxs{
        width: 100%;
        height: 100%;
       
        display: flex;
        justify-content: center;
       justify-content: space-between;
        align-items: center;
        flex-direction: column;
        gap: 2rem;
       
    }
}
@media  (max-width: 465px){
    .titles{
        margin-bottom: 1rem;
    }
    .festive{
         margin-left: 20px;
     }
     .product{
          margin-left: 20px;
     }
     .shop{
          margin-left: 20px;
     }
    .mainImg_1{
        width: 300px;
        height: 208.12px;
        }
        .heading{
            font-family: Rubik;
            font-size: 21px;
            font-weight: 500;
            margin-top: -0.1rem;
            text-align: center;
              }
        .text{
            font-family: Rubik;
            font-size: 12px;
            font-weight: 400;
           margin-top: -1rem;
            text-align: center;
        
        }
        .section{
            margin-top: 0rem;
            margin-bottom: 0rem;
        }
}